import React, { useState, useEffect } from "react";
import { ValidatorForm } from "react-form-validator-core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SelectValidator from "../partial/SelectValidator";
import TextValidator from "../partial/TextValidator";
import { selectValueGetter } from "../../utils/helper";
import { ButtonGroup } from "react-bootstrap";
import axios from "axios";
import { createOrder } from "../../services/auth";

const MemberShip = () => {
    const navigate = useNavigate();
    const [responseId, setResponseId] = React.useState("");
    const [responseState, setResponseState] = React.useState([]);
  
    const loadScript = (src) => {
      return new Promise((resolve) => {
        const script = document.createElement("script");
  
        script.src = src;
  
        script.onload = () => {
          resolve(true)
        }
        script.onerror = () => {
          resolve(false)
        }
  
        document.body.appendChild(script);
      })
    }
    const createRazorpayOrder = async(amount) => {
        const body = {
        amount: amount * 100,
        currency: "INR"
      }
  
       await createOrder(body)
   
      .then((response) => {
        handleRazorpayScreen(response.amount)
      })
      .catch((error) => {
      })
    }
  
    const handleRazorpayScreen = async(amount) => {
      const res = await loadScript("https:/checkout.razorpay.com/v1/checkout.js")
  
      if (!res) {
        alert("Some error at razorpay screen loading")
        return;
      }
  
      const options = {
        key: 'rzp_test_a8gaJIcrn9cRw6',
        amount: amount,
        currency: 'INR',
        name: "Membership Fee",
    
        description: "payment to Membership Fee",
        // image: "https://papayacoders.com/demo.png",
        handler: function (response){
          setResponseId(response.razorpay_payment_id)
          navigate("/confirmation", { state: { paymentId: response.razorpay_payment_id,membershipValue:membershipValue } });
        },
        prefill: {
          name: "Membership Fee",
        //   email: "papayacoders@gmail.com"
        },
        theme: {
          color: "#F4C430"
        }
      }
  
      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
    }
  
  
    const location = useLocation();
    const data = location?.state?.data;
    const type = location?.state?.type;
    
    const [membershipValue, setMembershipValue] = useState(type);
    const [membershipFee, setMembershipFee] = useState("");
    const [currencyValue] = useState("INR");

    const membershipType = [
        { label: 'VIP', value: 'VIP' },
        { label: 'VVIP', value: 'VVIP' },
    ];
    const currencyType = [
        { label: 'INR', value: 'INR' },
    ];
    const handleMemberShipValue = (selectedOption) =>{
        setMembershipValue(selectedOption.value)
    }
    useEffect(() => {
        if (membershipValue === 'VIP') {
            setMembershipFee(5000);
        } else if (membershipValue === 'VVIP') {
            setMembershipFee(2);
        }
    }, [membershipValue]);

    const handleSubmit = () => {
        // Handle form submission logic here
    };


    return (
        <div className="dashboard__content">
            <div className="container-fluid mt-3">
                <ValidatorForm className="signUpPage__formSection" onSubmit={handleSubmit} style={{padding:'50px 0 50px 0',}}>
                    <div className="col-md-8 offset-md-2">
                        <div className="card p-4">
                            <div className="my-3 text-center">
                                <h4 className="mb-4 text-dark">Pay for Membership</h4>
                            </div>
                            <div className="row mb-3">
                                <label className="col-md-3 col-form-label">
                                    MemberShip Type
                                </label>
                                <div className="col-md-9">
                                    <SelectValidator
                                        options={membershipType}
                                        value={membershipValue ? selectValueGetter(membershipType, membershipValue) : []}
                                        onChange={handleMemberShipValue}
                                    />
                                </div>
                            </div>
                            
                            <div className="row mb-3">
                                <label className="col-md-3 col-form-label">
                                    MemberShip Fee
                                </label>
                                <div className="col-md-9">
                                    <TextValidator
                                        className="form-control"
                                        value={membershipFee}
                                        readOnly
                                    />
                                </div>
                            </div>

                            <div className="row mb-3">
                                <label className="col-md-3 col-form-label">
                                    Currency
                                </label>
                                <div className="col-md-9">
                                    <SelectValidator
                                    options={currencyType}
                                    value={currencyValue ? selectValueGetter(currencyType, currencyValue) : []}
                                    disabled
                                />
                                </div>
                            </div>

                            <div className="row">
                                <label className="col-md-3">
                                    &nbsp;
                                </label>
                                <div className="col-md-9 text-center">
                                    <button className="btn btn-success d-inline-block py-2 px-3" onClick={() => createRazorpayOrder(membershipFee)}>
                                        Pay Now
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </ValidatorForm>
                {/* <button onClick={() => createRazorpayOrder(100)}>Payment of 100Rs.</button> */}
      {/* {responseId && <p>{responseId}</p>}
      <h1>This is payment verification form</h1>
      <form onSubmit={paymentFetch}>
        <input type="text" name="paymentId" />
        <button type="submit">Fetch Payment</button>
        {responseState.length !==0 && (
          <ul>
            <li>Amount: {responseState.amount / 100} Rs.</li>
            <li>Currency: {responseState.currency}</li>
            <li>Status: {responseState.status}</li>
            <li>Method: {responseState.method}</li>
          </ul>
        )}
      </form> */}
            </div>

            <div className="footer__Box">
                <div className="inner_footer">
                    <div className="inner_footerbox inner_footerbox_1">
                        <h4>Quick Links</h4>
                        <ul>
                            <li><a>Home</a></li>
                            <li><a>Rewards</a></li>
                            <li><a>History</a></li>
                            <li><a>Vouchers</a></li>
                            <li><a>Gift Cards</a></li>
                        </ul>
                    </div>
                    {data?.location?.length > 0 && (
                        <div className="inner_footerbox inner_footerbox_2">
                            <h4>Our Location</h4>
                            <p>{data?.location}</p>
                        </div>
                    )}
                    <div className="inner_footerbox inner_footerbox_3">
                        <h4>Contact Us</h4>
                        <ul>
                            <li><a>{data?.contactnumber}</a></li>
                            <li><Link to={`mailto:${data?.email}`}>{data?.email}</Link></li>
                            <li className="text-white">{data?.address}</li>
                        </ul>
                    </div>
                </div>
                <div className="copyright_box">
                    <div className="copyright_innerbox">
                        <ul>
                            <li>© 2024 The {data?.companyname}. All Rights Reserved</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MemberShip;




const PageData = ({ currentData, setCurrentData, pageData, hasNextPage, hasPreviousPage, value }) => {
    const pageNumbers = [];

    for (let i = 0; i < pageData; i++) {
        pageNumbers.push(i);
    }

    const getPaginationButtons = () => {
        let start = currentData - 2;
        let end = currentData + 2;
        if (start < 1) {
            end -= start - 1;
            start = 1;
        }
        if (end > pageNumbers.length) {
            end = pageNumbers.length;
        }
        return pageNumbers.slice(start - 1, end);
    };

    return (
        <ul>
            <li>
                <button
                    className="btn btn-outline-primary"
                    disabled={!currentData > 0}
                    onClick={() => {
                        setCurrentData(currentData - 1);
                    }}
                >
                    <i className="fa-solid fa-arrow-left"></i>
                </button>
            </li>
            {getPaginationButtons().map((i) => (
                <li key={i}>
                    <button
                        className="btn btn-outline-primary"
                        key={i}
                        disabled={i === currentData}
                        onClick={() => {
                            setCurrentData(i);
                        }}
                    >
                        {i + 1}
                    </button>
                </li>
            ))}
            <li>
                <button
                    className="btn btn-outline-primary"
                    disabled={pageData === currentData + 1}
                    onClick={() => {
                        setCurrentData(currentData + 1);
                    }}
                >
                    <i className="fa-solid fa-arrow-right"></i>
                </button>
            </li>
        </ul>
    );
};
export default PageData;

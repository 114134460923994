import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Slider from "react-slick";

import Banner from "../../../src/Image/banner-1.jpg";

import merchantImage from "../../../src/Image/merchantImage.png";

import swiggy1 from "../../../src/Image/swiggy-fig-1.jpg";
import swiggy2 from "../../../src/Image/swiggy-fig-2.jpg";
import swiggy3 from "../../../src/Image/swiggy-fig-3.jpg";

import copyIcon from "../../../src/Image/copyIcon.jpg";
import mailIcon from "../../../src/Image/mailIcon.jpg";
import fbMsgIcon from "../../../src/Image/fbMsgIcon.jpg";
import whatsAppIcon from "../../../src/Image/whatsAppIcon.jpg";
import linkedinIcon from "../../../src/Image/linkedinIcon.png";
import { getMerchantData, Token_Generator } from "../../services/auth";
import { getBanner, getCampaignPromotion, getMerchantAndLogo, getOffer } from "../../services/services";
import Loader from "../partial/Loader";

function Offer() {
  const [responseData, setResponseData] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [imageData, setImageData] = useState();
  const [loader, setLoader] = useState(true);
  const [merchantIds, setMerchantIds] = useState();
  const subdomain = window.location.hostname.split(".")[0];

  useEffect(() => {
    const fetch = async () => {
      setLoader(true);
      const token = await Token_Generator();
      const response = await getMerchantData(token?.access_token, subdomain)
      if (response.message === "success") {
        setMerchantIds(response?.merchantcode);
        await getMerchantAndLogo(token.access_token, response?.merchantcode).then((value) => {
          setImageData(value[0]?.blobFilePath);
        });
        const resOffer = await getOffer(token.access_token, response?.merchantcode)
        if (resOffer.message === "Offer data fetched successfully.") {
          if (resOffer?.data?.length) {
            setResponseData(resOffer?.data);
          }
        }
        await getBanner(token.access_token, response?.merchantcode).then((res) => {
          setBannerData(res[0]?.bannerDetails);
          setLoader(false);
        });
      } else {
        setLoader(false);
      }
    };
    fetch();
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  // const filteredResponse = responseData ? responseData.filter((item) => !Array.isArray(item.tags) || item.tags.length > 0) : [];

  return (
    <>
      {loader && <Loader />}
      <div className="offer-wapper">
        <header className="offer-header">
          <div className="container">
            <div className="offer-header__logo">
              <Link>
                <img src={imageData} alt="logo" />
              </Link>
            </div>
            <div className="offer-header-login-signup">
              <ul>
                <li>
                  <Link to={`/signin`}>Login</Link>
                </li>
                <li>
                  <Link to={`/signup`} className="signup">
                    Signup
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </header>
        {/* header end */}
        {/* <div className="share-refe-code">
          <div className="share-refe-code__inside">
            <div className="share-refe-code__inside__left">
              <span className="shareCodeText">Share your Referral Code: REF38272764</span>
              <Link className="shareIconLink">
                <img src={copyIcon} alt="icon" />
              </Link>
              <Link className="shareIconLink">
                <img src={mailIcon} alt="icon" />
              </Link>
              <Link className="shareIconLink">
                <img src={fbMsgIcon} alt="icon" />
              </Link>
              <Link className="shareIconLink">
                <img src={whatsAppIcon} alt="icon" />
              </Link>
              <Link className="shareIconLink">
                <img src={linkedinIcon} alt="icon" />
              </Link>
            </div>
            <div className="share-refe-code__inside__right">
              <span className="loyaltyPnt">Your Loyalty Points</span> <span className="lined">|</span> <strong>760</strong>
            </div>
          </div>
        </div>  */}

        <section className="offer-slider">
          <Slider {...settings}>
            {bannerData.map((item, index) => (
              <div className="offer-slider__item" key={index}>
                <img src={item.blobFilePath} alt={`banner-${index}`} />
              </div>
            ))}
            {/* <div className="offer-slider__item">
              <img src={Banner} alt="Banner 1" />
            </div> */}
            {/* <div className="offer-slider__item">
              <img src={Banner} alt="Banner 2" />
            </div>
            <div className="offer-slider__item">
              <img src={Banner} alt="Banner 3" />
            </div> */}
          </Slider>
        </section>

        <div className="offer-mid-container">
          <div className="container">
            <div className="section-header mb-4">
              <h2 className="">Unlock Rewards in 3 Simple Steps</h2>
              <p>Start earning points with every transaction and redeem them for exclusive rewards and benefits</p>
            </div>
            <div className="row mb-4">
              <div className="col-4 app-steps">
                <figure className="app-steps__number">1</figure>
                <figcaption>
                  <h4>Sign Up</h4>
                  <p>Join us and begin your journey<br /> toward those rewards.</p>
                </figcaption>
              </div>
              <div className="col-4 app-steps">
                <figure className="app-steps__number">2</figure>
                <figcaption>
                  <h4>Earn Points</h4>
                  <p>Earn X point for every $X spent.</p>
                </figcaption>
              </div>
              <div className="col-4 app-steps">
                <figure className="app-steps__number">3</figure>
                <figcaption>
                  <h4>Redeem Points</h4>
                  <p>Receive $X off for every X <br /> points redeemed.</p>
                </figcaption>
              </div>
            </div>

            <div className="offer-card-container">
              {/* <div className="offer-card-item">
                                      <div className="offer-card-item__fig">
                                        <img src={swiggy1} alt="Swiggy" />
                                      </div>
                                      <div className="offer-card-item__content">
                                        <h4>Debit Cards & Credit Cards</h4>
                                        <h6>Swiggy</h6>
                                        <p>Satiate your hunger with a jaw-dropping 15% discount on Swiggy using Federal Bank Cards.</p>
                                        <a href="#" className="btn btn-primary">Learn More</a>
                                      </div>
                                    </div>

                                    <div className="offer-card-item">
                                      <div className="offer-card-item__fig">
                                        <img src={swiggy2} alt="Swiggy" />
                                      </div>
                                      <div className="offer-card-item__content">
                                        <h4>Debit Cards & Credit Cards</h4>
                                        <h6>Swiggy</h6>
                                        <p>Satiate your hunger with a jaw-dropping 15% discount on Swiggy using Federal Bank Cards.</p>
                                        <a href="#" className="btn btn-primary">Learn More</a>
                                      </div>
                                    </div>

                                    <div className="offer-card-item">
                                      <div className="offer-card-item__fig">
                                        <img src={swiggy3} alt="Swiggy" />
                                      </div>
                                      <div className="offer-card-item__content">
                                        <h4>Debit Cards & Credit Cards</h4>
                                        <h6>Swiggy</h6>
                                        <p>Satiate your hunger with a jaw-dropping 15% discount on Swiggy using Federal Bank Cards.</p>
                                        <a href="#" className="btn btn-primary">Learn More</a>
                                      </div>
                                    </div> */}
              {responseData &&
                responseData.map((item, ind) => (
                  <div className="offer-card-item newOffers" key={ind}>
                    <div className="offer-card-item__inside">
                      <figure className="offer-card-item__inside__figure">
                        <img src={item.offerimage} />
                        <figcaption className="offerTitle">
                          <h4 title={item.offertitle}>{item.offertitle}</h4>
                        </figcaption>
                      </figure>
                      {/* <div className="offer-card-item__inside__body">
                        <p>{item.offerdescription}</p>
                      </div> */}
                    </div>
                  </div>
                ))}
            </div>

            <div className="accordion-container">
              <div class="section-header mb-4">
                <h2 className="text-capitalize">Frequently Asked Question</h2>
                {/* <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus, laborum.</p> */}
              </div>

              <div id="accordion">
                <div className="card">
                  <div className="card-header">
                    <h5 className="mb-0">
                      <button className="btn btn-link" data-toggle="collapse" data-target="#accordion_1" aria-expanded="true" aria-controls="accordion_1">
                        How do I earn points? <i className="iconx">&nbsp;</i>
                      </button>
                    </h5>
                  </div>
                  <div id="accordion_1" className="collapse show" data-parent="#accordion">
                    <div className="card-body">
                      <p>You earn points by making eligible purchases, engaging with our services, or participating in promotions. Specific details on how points are accumulated can be found in the program terms.</p>
                    </div>
                  </div>
                </div>


                <div className="card">
                  <div className="card-header">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#accordion_2" aria-expanded="false" aria-controls="accordion_2">
                        Are there limitations on how many points I can earn? <i className="iconx">&nbsp;</i>
                      </button>
                    </h5>
                  </div>
                  <div id="accordion_2" className="collapse" data-parent="#accordion">
                    <div className="card-body">
                      <p>There may be caps on the number of points you can earn within a specific time period. Check the program’s rules for any limitations.</p>
                    </div>
                  </div>
                </div>


                <div className="card">
                  <div className="card-header">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#accordion_3" aria-expanded="false" aria-controls="accordion_3">
                        Do my points expire? <i className="iconx">&nbsp;</i>
                      </button>
                    </h5>
                  </div>
                  <div id="accordion_3" className="collapse" data-parent="#accordion">
                    <div className="card-body">
                      <p>Yes, points typically have an expiration period. Be sure to redeem your points before they expire, as specified in the program terms.</p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#accordion_4" aria-expanded="false" aria-controls="accordion_4">
                        How do I use my points to redeem rewards? <i className="iconx">&nbsp;</i>
                      </button>
                    </h5>
                  </div>
                  <div id="accordion_4" className="collapse" data-parent="#accordion">
                    <div className="card-body">
                      <p>You can redeem your points by selecting available rewards through your account or at checkout, depending on the program. Instructions are provided on the redemption page.</p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#accordion_5" aria-expanded="false" aria-controls="accordion_5">
                        Can I earn points when purchasing or applying a gift card? <i className="iconx">&nbsp;</i>
                      </button>
                    </h5>
                  </div>
                  <div id="accordion_5" className="collapse" data-parent="#accordion">
                    <div className="card-body">
                      <p>Points are generally not awarded for purchasing or applying gift cards, but this can vary by program. Please check the specific program guidelines.</p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#accordion_6" aria-expanded="false" aria-controls="accordion_6">
                        Is there a limit to how many points I can earn? <i className="iconx">&nbsp;</i>
                      </button>
                    </h5>
                  </div>
                  <div id="accordion_6" className="collapse" data-parent="#accordion">
                    <div className="card-body">
                      <p>Some programs may impose a limit on how many points can be earned within a certain period. Please refer to the program’s terms for details.</p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#accordion_7" aria-expanded="false" aria-controls="accordion_7">
                        Can I earn points if I’m not logged in to my account? <i className="iconx">&nbsp;</i>
                      </button>
                    </h5>
                  </div>
                  <div id="accordion_7" className="collapse" data-parent="#accordion">
                    <div className="card-body">
                      <p>To earn points, you usually need to be logged in to your account at the time of purchase. Be sure to log in to ensure your points are credited.</p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#accordion_8" aria-expanded="false" aria-controls="accordion_8">
                        If I have more than one account, can I combine my points and rewards? <i className="iconx">&nbsp;</i>
                      </button>
                    </h5>
                  </div>
                  <div id="accordion_8" className="collapse" data-parent="#accordion">
                    <div className="card-body">
                      <p>Points and rewards are typically non-transferable and cannot be combined between accounts.</p>
                    </div>
                  </div>
                </div>


                <div className="card">
                  <div className="card-header">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#accordion_9" aria-expanded="false" aria-controls="accordion_9">
                        Why do I have a negative points balance? <i className="iconx">&nbsp;</i>
                      </button>
                    </h5>
                  </div>
                  <div id="accordion_9" className="collapse" data-parent="#accordion">
                    <div className="card-body">
                      <p>A negative balance can occur if points are adjusted due to returns, cancellations, or program errors.</p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#accordion_10" aria-expanded="false" aria-controls="accordion_10">
                        Why can’t I apply my coupon code or gift card at checkout? <i className="iconx">&nbsp;</i>
                      </button>
                    </h5>
                  </div>
                  <div id="accordion_10" className="collapse" data-parent="#accordion">
                    <div className="card-body">
                      <p>Some promotions or rewards may not be combinable with other offers. Check the terms of your coupon or gift card for restrictions.</p>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#accordion_11" aria-expanded="false" aria-controls="accordion_11">
                        Can I combine my loyalty program points with other promotions? <i className="iconx">&nbsp;</i>
                      </button>
                    </h5>
                  </div>
                  <div id="accordion_11" className="collapse" data-parent="#accordion">
                    <div className="card-body">
                      <p>In most cases, points cannot be combined with other promotions or discounts. However, this depends on the program’s specific rules.</p>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>

        <footer className="offer-footer">
          <div className="container">
            <p className="copyr">© Copyright Compcare Urgent Care. All Rights Reserved.</p>
            {/* <div className="offer-footer__nav">
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/offer">Offer</Link></li>
              </ul>
            </div> */}
          </div>
        </footer>
      </div>
      {/* offer-wapper end */}
    </>
  );
}

export default Offer;

import React, { useState } from "react";
import Slider from "react-slick";

import swiggy1 from "../../../../src/Image/swiggy-fig-1.jpg";
import { Modal } from "react-bootstrap";

function AuthenticationSliderLeft({ bannerImg, response }) {
    const [show, setShow] = useState(false);
    let settings = {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 5000,
        // adaptiveHeight: true,
    };
    const endTour = () => {
        setShow(false);
    };
    const onClick = () => {
        window.open("https://maps.app.goo.gl/9UQqMLpqxZ6fozsBA", "_blank");
    };
    const filteredResponse = response ? response.filter((item) => !Array.isArray(item.tags) || item.tags.length > 0) : [];
    return (
        <div className="signUpPage__left">
            <div className="signUpPage__slider">
                {filteredResponse.length > 0 ? (
                    <Slider {...settings}>
                        {filteredResponse.map((item, ind) => (
                            <div className="offer-card-item" key={ind}>
                                <div className="card-item-center-block" dangerouslySetInnerHTML={item.description ? { __html: item.description } : null}></div>
                                {/* <button onClick={onClick}>Onclick</button> */}
                            </div>
                        ))}
                    </Slider>
                ) : (
                    <Slider {...settings}>
                        {/* <div className="offer-card-item">
                            
                            {response && response.map((item)=>(
                                    <p dangerouslySetInnerHTML={item.description ? { __html: item.description } : null}></p>
                            ))}
                        </div> */}
                        {/* <div className="offer-card-item">
                                <div className="offer-card-item__fig">
                                    <img src={"/Images/carouselSecond.png"} alt="Swiggy" />
                                </div>
                                <div className="offer-card-item__content">
                                    <h4>Debit Cards & Credit Cards</h4>
                                    <h6>Swiggy</h6>
                                    <p>Satiate your hunger with a jaw-dropping 15% discount on Swiggy using Federal Bank Cards.</p>
                                    <a href="#" className="btn btn-primary">Learn More</a>
                                </div>
                            </div> */}
                        {/* <div className="signUpPage__box">
                            <h4>Engage</h4>
                            <h2>Boost active participation through engagement with the right audience</h2>
                            <p>Know your customers to create personalized content & deliver through their preferred channels at the right time</p>
                            <figure>
                                <img src={"/Images/carouselSecond.png"} alt="" />
                            </figure>
                        </div>
                        <div className="signUpPage__box">
                            <h4>Retain</h4>
                            <h2>Build long-lasting customer relationships with an enhanced loyalty experience</h2>
                            <p>Use Novus to turn existing customers into repeat buyers & prevent them from switching to competitors</p>
                            <figure>
                                <img src={"/Images/carouselThird.png"} alt="" />
                            </figure>
                        </div>
                        <div className="signUpPage__box">
                            <h4>Grow</h4>
                            <h2>Embrace data-driven actionable insights to meet customer expectations</h2>
                            <p>Develop an evergreen growth strategy to earn more customers, increase their lifetime value & reduce cost per acquisition</p>
                            <figure>
                                <img src={"/Images/carouselFourth.png"} alt="" />
                            </figure>
                        </div>   */}

                        {/* <div className="offer-card-item">
                                <div className="offer-card-item__fig">
                                    <img src={"/Images/banner2.PNG"} alt="" />
                                </div>
                                <div className="offer-card-item__content">
                                    <h4>Debit Cards & Credit Cards</h4>
                                    <h6>Swiggy</h6>
                                    <p>Satiate your hunger with a jaw-dropping 15% discount on Swiggy using Federal Bank Cards.</p>
                                    <a href="#" className="btn btn-primary">Learn More</a>
                                </div>
                            </div> */}
                        <div className="offer-card-item">
                            <div className="offer-card-item__fig">
                                <img src={"/Images/earn_rewards 1.png"} alt="" />
                            </div>
                            {/* <div className="offer-card-item__content">
                                    <h4>Debit Cards & Credit Cards</h4>
                                    <h6>Swiggy</h6>
                                    <p>Satiate your hunger with a jaw-dropping 15% discount on Swiggy using Federal Bank Cards.</p>
                                    <a href="#" className="btn btn-primary">Learn More</a>
                                </div> */}
                        </div>
                    </Slider>
                )}
            </div>
            {/* <Modal show={show} onHide={endTour} centered>
        <Modal.Header className="custom_header" closeButton></Modal.Header>
        <Modal.Body className="dashboard__transactionsModal">
       
          
          
        
        </Modal.Body>
      </Modal> */}
        </div>
    );
}

export default AuthenticationSliderLeft;

import React from "react";
import { Modal, CloseButton } from "react-bootstrap";
import { getDate } from "../../utils/helper";

const RedeemModal = (props) => {
    const { show, close, walletSummary } = props;

    return (
        <>
            <Modal show={show} onHide={close} centered>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: "20px" }}>Available Reward For Redeem</Modal.Title>
                    <CloseButton onClick={close} variant="white" />
                </Modal.Header>
                <Modal.Body style={{ position: "relative", minHeight: "85px" }}>
                    {walletSummary?.length ? (
                        walletSummary.map((wallet, i) => (
                            <div key={i} className="m-1 p-2">
                                <label>Redeem Points After Date : </label> {getDate(wallet?.redeemableAfterDate, "YYYY-MM-DD")}
                                <br />
                                <label>Value To Redeem : </label> {wallet?.avaliableValueToRedeem}
                            </div>
                        ))
                    ) : (
                        <label>Value to Redeem : 0</label>
                    )}
                </Modal.Body>

                <Modal.Footer></Modal.Footer>
            </Modal>
        </>
    );
};

export default RedeemModal;


import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { getDate } from "../../utils/helper";
import { giftCard, voucher } from "../../Image/Index";
import { useLocation } from 'react-router-dom';
import { highlight } from "../../Image/Index";
import Card from "./Card";
import { customerView, getGeneratedVoucher, orderHistory } from "../../services/services";
import { decrypt, encrypt } from "../../utils/encodingdecoding";
import Footer from "./Footer";
import Loader from "../partial/Loader";


function RewardsHistory() {
    const [loader, setLoader] = useState(true);
    const location = useLocation();
    const { state,Giftcards } = location.state || {};
     console.log(state,Giftcards,"arifffff")
    const [buttons, setButtons] = useState({
        home: false,
        choose_rewards: false,
        history_rewards: true,
        inStore: false,
    });

    const [share, setShare] = useState({
        shareUrl: "",
        copied: false,
    });
    const { referralCode, uploadModal, redemptionModal, email, banner, displayUploadReceipt, displayStoreRedemption, pointImg, socialMedia ,flag } = state||{};
    const { home, choose_rewards, inStore, history_rewards } = buttons;
    const { shareUrl, copied } = share;

    const [toggle, setToggle] = useState({
        isActive: false,
        index: null,
    });
    // console.log(state, "arif" )
    const [homeState, setHomeState] = useState({
        showModal: false,
        history: [],
        enableCamp: [],
        linkUrl: [
            {
                camp: [],
                url: "",
            },
        ],
        disableCamp: [],
    });

    const toggleActive = (ind) => {
        if (index === ind) {
            setToggle({ ...toggle, index: ind, isActive: !isActive });
        } else {
            setToggle({ ...toggle, index: ind, isActive: true });
        }
    };
    
    const { history, } = homeState;
     const { activityLogs} = state||{};
    const { isActive, index } = toggle;

    const [walletsDetail,setWalletsDetail]=useState()
    const [orderList,setOderList]=useState()
    const [rewards,setReward]=useState()
    // console.log(activityLogs,"activity")
    useEffect(() => {
        const temp = activityLogs?.reverse() && activityLogs?.filter((ele) => ele.transactionType && ele);
        setHomeState((prev) => ({ ...prev, history: temp }));
    }, [activityLogs]);
    const handleCopy = () => {
        setShare((prev) => ({ ...prev, copied: true }));
        setTimeout(() => {
            setShare((prev) => ({ ...prev, copied: false }));
        }, 3000);
    };

    useEffect(() => {
        if (location?.state?.home) {
            setButtons((prev) => ({
                ...prev,
                home: location?.state?.home,
                choose_rewards: location?.state?.choose_rewards,
                history_rewards: location?.state?.history_rewards,
                inStore: false,
            }));
        }
    }, [location]);

    
useEffect(()=>{
    setLoader(true)
    const token = JSON.parse(localStorage.getItem("token_gen"));
    const customerCode = localStorage.getItem("c_code");
    const id = localStorage.getItem("Id");
     customerView(token.access_token, customerCode).then(profileData => {
             setWalletsDetail(profileData?.walletsDetail)
             setLoader(false)
         }) 

},[])
    useEffect(() => {
        const fetch = async () => {
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const customerCode = localStorage.getItem("c_code");
            const id = localStorage.getItem("Id");
             customerView(token.access_token, customerCode).then(profileData => {
                     setWalletsDetail(profileData?.walletsDetail)
                 }) 

                 let orderList = orderHistory(customerCode, encrypt  ("Novus1f4-87bg-4b69-be25-31240m8fsaas")).then(orderList=>{
                    setOderList(orderList?.data)
                 });

                 let rewards = getGeneratedVoucher(token.access_token, customerCode).then(rewards=>{
                    setReward(rewards?.data)
                 });
            // let rewards = customerView(token.access_token, customerCode).then(rewards => {
            //     console.log(rewards,"reeeeeee")
            // })    
        };
        fetch();
    }, []);

    return (
        <div>
                {loader && <Loader />}
            <div className="customer_portal__mainPage">
                <div className="customer_portal__content">
                <div className="customer_portal__headerbox">
                 <div className="tabs_box">   
                    <Card handleCopy={handleCopy} home={home} referralCode={referralCode} copied={copied} shareUrl={shareUrl} setButtons={setButtons} displayStoreRedemption={displayStoreRedemption} inStore={inStore} state={state} pointImg={pointImg} highlight={highlight} walletsDetail={walletsDetail} choose_rewards={choose_rewards} history_rewards={history_rewards}/>
                 

           <Tabs defaultActiveKey={Giftcards=='Giftcards'?"Giftcards":'History'} id="customerViewPointsTabs">
                <Tab  eventKey="History" title="History">
                    {history?.length ? (
                        <div className="history_row">
                            {history?.map((ele, i) => (
                                
                                <div className="history_box" key={i}>
                                   
                                    <div className="history_details">
                                        <h5>Trans ID: {ele.transactionId}</h5>
                                        <p>{getDate(ele.transctionDate, "YYYY-MM-DD")}</p>
                                    </div>
                                    <div className="history_points">{ele?.transactionType?.toUpperCase() === "PURCHASE" ? <p className="success">{ele.value || !ele.voucherCode ? `${ele?.value} Points Earned` : ele.voucherCode && `${ele.valueType} Earned Worth ${ele.value}`}</p> : ele?.transactionType?.toUpperCase() === "REDEEM" ? <p className="redeemed">{ele?.value} Points Redeemed</p> : ele?.transactionType?.toUpperCase() === "VOUCHERPURCHASE" ? <p className="success"> Voucher Earned Worth {ele?.value} </p> : ele?.transactionType?.toUpperCase() === "REFUND" ? <p className="refunded">{ele?.value} Points Refunded</p> : null}</div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <h6 className="text-center"> No Record Found</h6>
                    )}
                </Tab>
                <Tab eventKey="Rewards" title="Rewards">
                    {rewards?.voucherData?.length > 0 ? (
                        <div className="rewards_row">
                            {rewards?.voucherData.map((vou, i) => (
                                <div className="rewards_box" key={i}>
                                    <div className="rewards_ibox">
                                        {rewards?.templateImage.map(
                                            (template, i) =>
                                                template.templateCode === vou.voucherTemplateCode && (
                                                    <div key={i}>
                                                        <img src={template.templateImage ? template.templateImage : voucher} alt="rewards" />
                                                    </div>
                                                )
                                        )}
                                    </div>
                                    <div className="rewards_cbox">
                                        {rewards?.templateImage.map(
                                            (template, i) =>
                                                template.templateCode === vou.voucherTemplateCode && (
                                                    <div key={i}>
                                                        <div className="rewards_cbox">
                                                            <h4>{template.templateName}</h4>
                                                        </div>
                                                    </div>
                                                )
                                        )}
                                        <p>
                                            Voucher Code: <span>{vou.voucherCode}</span>
                                        </p>
                                        <p>
                                            Voucher Value: <span>{vou.earnedValue}</span>
                                        </p>
                                        <p>
                                            Expiry Date: <span>{getDate(vou.expiryDate, "YYYY-MM-DD")}</span>
                                        </p>
                                        <small>
                                            <span className="giftStar">*</span>Gift cards or vouchers once bought cannot be exchanged, refunded or cancelled
                                        </small>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <h6 className="text-center"> No Record Found</h6>
                    )}
                </Tab>
                <Tab  eventKey="Giftcards" title="Giftcards">
                    {orderList?.length ? (
                        <div className="giftcards_row">
                            {orderList?.map((ele, ind) =>
                                ele?.item_list.map((item) => (
                                    <div className={`giftcards_box ${index === ind && isActive === true ? "active" : ""}`} onClick={() => toggleActive(ind)} key={ind}>
                                        <div className="giftcards_box_inner">
                                            <div className="giftcards_ibox">
                                                <img src={item?.image ? item?.image : giftCard} alt="gift card" />
                                            </div>
                                            <div className="giftcards_cbox">
                                                <h4>{item?.name}</h4>
                                                <p>
                                                    Quantity: <span>{item?.quantity}</span>
                                                </p>
                                                {/* <p>
                                                    Price: <span>1</span>
                                                </p> */}
                                                <ul>
                                                    <li>Pts: {ele?.total_points_redeemed}</li>
                                                    <li className="refunded">Order Placed</li>
                                                </ul>
                                                <small>Gift cards or vouchers once bought cannot be exchanged, refunded or cancelled.</small>
                                            </div>
                                        </div>
                                        <div className="giftcards_obox">
                                            <p>
                                                Order ID: <strong>{ele?.code}</strong> Order On: <strong>{getDate(ele?.order_date, "YYYY-MM-DD hh:mm:ss")}</strong>
                                            </p>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    ) : (
                        <h6 className="text-center"> No Record Found</h6>
                    )}
                </Tab>
            </Tabs>
            </div>
            </div>   
            </div>
            </div> 
            <Footer />
        </div>
        

    )
}

export default RewardsHistory

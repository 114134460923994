import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../partial/TextValidator";
import PasswordValidator from "../partial/PasswordValidator";
import { Internal_Token, Token_Generator, signUp } from "../../services/auth";
import { getCampaignPromotion, getMerchantAndLogo, transaction, sendEmail, generateMobileOtp } from "../../services/services";
import Loader from "../partial/Loader";
import { alertModal } from "../../utils/helper";
import { decrypt, encrypt } from "../../utils/encodingdecoding";
import Slider from "react-slick";

import swiggy1 from "../../../src/Image/swiggy-fig-1.jpg";
import AuthenticationSliderLeft from "./common/AuthenticationSliderLeft";

const SignUp = () => {
    const formRef = useRef();
    const [responseData, setResponseData] = useState([]);
    const [isRevealPassword, setIsRevealPassword] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: "",
        emailID: "",
        contactNumber: "",
        newPassword: "",
        referredby: "",
        tAndC: false,
        error: false,
        otp: Math.floor(Math.random() * 10000),
    });
    const [isReferralCode, setIsReferralCode] = useState(false);
    const [finalData, setFinalData] = useState({});
    const [loader, setLoader] = useState(true);
    const [logo, setLogo] = useState("");
    const [uploadSource, setUploadSource] = useState("");
    const invalidDomains = ["mailinator", "cwmxc", "horsgit", "maildrop", "explodemail", "stresspc", "anonaddy"];

    const { name, emailID, contactNumber, newPassword, tAndC, error, referredby, otp } = formData;
    const onChange = (e) => setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    useEffect(() => {
        const validateEmail = (email) => {
            const domainPattern = invalidDomains.join("|");
            const regexPattern = `^(?!.*@(${domainPattern})\\b)([a-zA-Z0-9]+([._-][a-zA-Z0-9]+)*)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$`;

            const regex = new RegExp(regexPattern);
            if (email) {
                if (regex.test(email)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        };
        ValidatorForm.addValidationRule("validEmail", (value) => {
            if (validateEmail(value)) {
                return true;
            }
            return false;
        });
        // eslint-disable-next-line
    }, [emailID]);

    useEffect(() => {
        const validateName = (name) => {
            return /^[A-Za-z\s]*$/.test(name);
        };
        ValidatorForm.addValidationRule("validName", (value) => {
            if (validateName(value)) {
                return true;
            }
            return false;
        });
    }, [name]);

    useEffect(() => {
        let data = [];
        data.push({ name: name, mobile: contactNumber, email: emailID, password: newPassword, referredby: isReferralCode ? referredby : "", otp: otp.toString() });
        setFinalData(data[0]);
    }, [formData, isReferralCode]); // eslint-disable-line

    useEffect(() => {
        const logo = async () => {
            // const token = JSON.parse(localStorage.getItem("token_gen"));
            const id = localStorage.getItem("Id");
            const token = await Token_Generator();
            const response = await getMerchantAndLogo(token.access_token, id);
            setLogo(response[0]?.blobFilePath);
            setUploadSource(response[0]?.uploadedSource);
            // const responsee = await getCampaignPromotion(token.access_token, id);
            // setResponseData(responsee.data);
            setLoader(false);
        };
        logo();
    }, []);
    const handleCheckbox = (e) => setFormData({ ...formData, tAndC: e.target.checked });

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!tAndC) {
            setFormData({ ...formData, error: true });
        } else {
            setLoader(true);
            let tokenBody = {
                clientid: localStorage.getItem("client_id"),
                grant_type: "client_credentials",
                clientsecret: localStorage.getItem("client_secret"),
            };
            const token = await Internal_Token(tokenBody);
            const response = await signUp(token?.access_token, finalData);
            if (response.message.message === "Request processed successfully.") {
                localStorage.setItem("mobile", encrypt(contactNumber));
                const tempEmail = {
                    subject: "Registration verification",
                    sendgridApiKey: "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",
                    fromEmail: "support@loyalty.novusloyalty.com",
                    fromName: "Novus Loyalty",
                    emails: [
                        {
                            email: emailID,
                            name: name,
                        },
                    ],
                    body: `<!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>OTP </title>
                    </head>
                    <body style="margin: 0;">
                       <p>Your OTP for verification ${otp} </p>
                    </body>
                    </html>`,
                };
                const mobileBody = {
                    authkey: "332811AofgzqKp5eeadc8dP1",
                    mobile: `+91${contactNumber}`,
                    template_id: "65115af8d6fc05216e79d6b2",
                    otp_expiry: 15,
                };
                const emailRes = await sendEmail(token.access_token, tempEmail);
                const mobileRes = await generateMobileOtp(mobileBody);
                if (emailRes.message === "Sending Email SuccessFull" || mobileRes.type === "success") {
                    setLoader(false);
                    alertModal("center", "success", "OTP send on your registered email ID", "button", "ok", 5000);
                    navigate("/otp-verification", { state: { responseData: responseData, logo: logo, uploadSource: uploadSource } });
                    const body = {
                        issignup: "yes",
                        mobile: contactNumber,
                        schemacode: "TSC000024",
                    };
                    await transaction(token?.access_token, body);
                    if (isReferralCode === true) {
                        const body = {
                            referrer: referredby,
                            referee: contactNumber,
                            schemacode: "TSC000024",
                        };
                        await transaction(token?.access_token, body);
                    }
                } else {
                    alertModal("center", "error", emailRes.message, "button", "OK", 5000);
                }
            } else if (response.message.message === "Customer already exists") {
                alertModal("center", "warning", "Customer already exists", "button", "oh no!", 5000);
                setLoader(false);
            } else {
                alertModal("center", "error", response.message.message, "button", "OK", 5000);
                setLoader(false);
            }
            setLoader(false);
        }
    };

    const handleLinkClick = (e) => {
        e.preventDefault();
        window.open(e.target.href, "_blank");
    };

    return (
        <section className="section signUpPage newStyle">
            {loader && <Loader />}
            <div className="signUpPage__main">
                {/* <div className="signUpPage__left">
                    <div className="sliderBox">
                        <Slider {...settings}>
                            <div className="offer-card-item">
                                <div className="offer-card-item__fig">
                                    <img src={swiggy1} alt="Swiggy" />
                                </div>
                                <div className="offer-card-item__content">
                                    <h4>Debit Cards & Credit Cards</h4>
                                    <h6>Swiggy</h6>
                                    <p>Satiate your hunger with a jaw-dropping 15% discount on Swiggy using Federal Bank Cards.</p>
                                    <a href="#" className="btn btn-primary">Learn More</a>
                                </div>
                            </div>
                            <div className="offer-card-item">
                                <div className="offer-card-item__fig">
                                    <img src={swiggy1} alt="Swiggy" />
                                </div>
                                <div className="offer-card-item__content">
                                    <h4>Debit Cards & Credit Cards</h4>
                                    <h6>Swiggy</h6>
                                    <p>Satiate your hunger with a jaw-dropping 15% discount on Swiggy using Federal Bank Cards.</p>
                                    <a href="#" className="btn btn-primary">Learn More</a>
                                </div>
                            </div>
                            <div className="offer-card-item">
                                <div className="offer-card-item__fig">
                                    <img src={swiggy1} alt="Swiggy" />
                                </div>
                                <div className="offer-card-item__content">
                                    <h4>Debit Cards & Credit Cards</h4>
                                    <h6>Swiggy</h6>
                                    <p>Satiate your hunger with a jaw-dropping 15% discount on Swiggy using Federal Bank Cards.</p>
                                    <a href="#" className="btn btn-primary">Learn More</a>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div> */}
                {/* <AuthenticationSliderLeft response={responseData} /> */}
                <div className="signUpPage__right">
                    <div className="signUpPage__right__inside">
                        <div className="signUpPage__heading">
                            <figure>{logo ? <img src={logo} alt="logo" /> : <h1>{uploadSource}</h1>}</figure>
                            <h1>Great to see you here!</h1>
                        </div>

                        <div className="signUpPage__form">
                            <ValidatorForm className="signUpPage__formSection" ref={formRef} autoComplete="off" autoCapitalize="off" onSubmit={(e) => onSubmit(e)}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TextValidator className="form-control" placeholder="Name" name="name" maxLength="50" value={name} onChange={onChange} validators={["required", "trim", "validName"]} errorMessages={["Please enter your name", "Enter valid Name", "Enter valid Name"]} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TextValidator className="form-control" placeholder="Email" name="emailID" maxLength="50" value={emailID} type="email" onChange={onChange} validators={["required", "validEmail"]} errorMessages={["Please enter your email", "Enter valid email"]} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TextValidator className="form-control" placeholder="Contact Number" name="contactNumber" maxLength="50" value={contactNumber} onChange={onChange} validators={["required", "matchRegexp:^(?!\\d*(\\d)\\1{7,}|0123456789|1234567890)\\d{10,15}$"]} errorMessages={["Please enter your contact number", "Please enter a valid number"]} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group withIcon">
                                            <span className="passwordToggle">{passwordFocus ? <img className="onFocus" title={isRevealPassword ? "Hide password" : "Show password"} src={isRevealPassword ? "/Images/eyeUnseenFocus.png" : "/Images/eyeSeenFocus.png"} alt="eye" onClick={() => setIsRevealPassword((prevState) => !prevState)} /> : <img className="onBlur" title={isRevealPassword ? "Hide password" : "Show password"} src={isRevealPassword ? "/Images/eyeUnseen.png" : "/Images/eyeSeen.png"} alt="eye" onClick={() => setIsRevealPassword((prevState) => !prevState)} />}</span>
                                            <PasswordValidator placeholder="New Password" className="form-control" name="newPassword" minLength="6" maxLength="16" value={newPassword} type={isRevealPassword ? "text" : "password"} onChange={onChange} validators={["required", "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,16}$"]} errorMessages={["Please enter password.", "condition"]} onFocus={() => setPasswordFocus(true)} onBlur={() => setPasswordFocus(false)} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group withIcon">
                                            <button className="btn btn-primary" type="button" onClick={() => setIsReferralCode(!isReferralCode)}>
                                                Have a Referral Code?
                                            </button>
                                        </div>
                                    </div>
                                    {isReferralCode && (
                                        <div className="col-md-6">
                                            <div className="form-group withIcon">
                                                <TextValidator className="form-control" placeholder="Enter Referral Code" name="referredby" maxLength="50" value={referredby} onChange={onChange} validators={["required"]} errorMessages={["Please enter referral code"]} />
                                            </div>
                                        </div>
                                    )}
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="signup_terms" className="signup_checklabel">
                                                <input type="checkbox" onChange={handleCheckbox} id="signup_terms" />
                                                <Link to="/terms-and-conditions" onClick={handleLinkClick}>
                                                    I agree to terms & conditions
                                                </Link>
                                            </label>
                                            {error && !tAndC && <span className="textValidatorError">Please agree to T&C</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary btn-lg w-100">
                                                Submit
                                            </button>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <p>
                                                Already have an account? <Link to={"/signin"}>Click here to login</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </ValidatorForm>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SignUp;

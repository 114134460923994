import React, { useEffect, useState } from 'react'
import axios from "axios";
import { Link, useLocation } from 'react-router-dom';
import { Payment, UpdateMembership, UpdateTransaction } from '../../services/auth';
import Loader from '../partial/Loader';
import { getDate } from '../../utils/helper';

function ConfimationPage() {
    const location = useLocation();
    const data = location?.state?.data;
    const paymentId = location?.state?.paymentId;
    const membershipValue = location?.state?.membershipValue;
    const [responseState, setResponseState] = useState();
    const [loader, setLoader] = useState(true);
    function addOneYear(date) {
        date.setFullYear(date.getFullYear() + 1);
        return date;
      }
      const date = new Date();
      const newDate = addOneYear(date);
      const addDate = getDate(newDate, "DD/MM/YYYY:HH:mm")
    useEffect(() => {
        const fetch = async () => {
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const id = localStorage.getItem("Id");
            const email = localStorage.getItem("email");
            const responseData = await Payment(paymentId);
            setResponseState(responseData);
            const currentDateTime = new Date();
            const currentDate = getDate(currentDateTime, "DD/MM/YYYY:HH:mm")
            const body = {
                
                    TransactionDate: currentDate,
                    membershipType: membershipValue,
                    amount: responseData.amount,
                    transactionStatus: responseData.status === "authorized" ?  true : false
                
            };
            const bodyData = {
                
                
                    membershipDate: currentDate,
                    membershipType: membershipValue,
                    cardNo: "",
                    validity: addDate
                
            
        };
            if(responseData.status === "authorized"){
                const banner = await UpdateTransaction(token?.access_token, id,email,body);
                const datas = await UpdateMembership(token?.access_token, id,email,bodyData);
            }
            else {
                const banner = await UpdateTransaction(token?.access_token, id,email,body);
            }
        
            // let profileData = customerView(token?.access_token, customerCode);

            // let camp = getCampaigns(token?.access_token, id);

           


                setLoader(false);
            
        };
        fetch();
    }, []);


  return (
    <>
         {loader && <Loader />}
    <div className='conditionPage'>
        <div className='conditionPage__card'>
            <h4 className='mb-3'>Your Payment has been paid successfully</h4>
            <Link className="btn btn-success d-inline-block px-3 py-2">Go to Home Page</Link>
        </div>
    </div>


    <div className="footer__Box">
                <div className="inner_footer">
                    <div className="inner_footerbox inner_footerbox_1">
                        <h4>Quick Links</h4>
                        <ul>
                            <li><a>Home</a></li>
                            <li><a>Rewards</a></li>
                            <li><a>History</a></li>
                            <li><a>Vouchers</a></li>
                            <li><a>Gift Cards</a></li>
                        </ul>
                    </div>
                    {data?.location?.length > 0 && (
                        <div className="inner_footerbox inner_footerbox_2">
                            <h4>Our Location</h4>
                            <p>{data?.location}</p>
                        </div>
                    )}
                    <div className="inner_footerbox inner_footerbox_3">
                        <h4>Contact Us</h4>
                        <ul>
                            <li><a>{data?.contactnumber}</a></li>
                            <li><Link to={`mailto:${data?.email}`}>{data?.email}</Link></li>
                            <li className="text-white">{data?.address}</li>
                        </ul>
                    </div>
                </div>
                <div className="copyright_box">
                    <div className="copyright_innerbox">
                        <ul>
                            <li>© 2024 The {data?.companyname}. All Rights Reserved</li>
                        </ul>
                    </div>
                </div>
            </div>
    </>
   
  )
}

export default ConfimationPage
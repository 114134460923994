import React from "react";
import { Navigate } from "react-router-dom";
const PublicRoute = ({ children }) => {
    const temp = JSON.parse(localStorage.getItem("token_gen"));
    if (temp?.access_token) {
        return <Navigate to="/customer-portal" />;
    }
    return children;
};

export default PublicRoute;

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { alertModal } from "../utils/helper";
import { decrypt } from "../utils/encodingdecoding";

const LogoutTimer = () => {
    const [lastActive, setLastActive] = useState(new Date());
    const logoutTimeout = 30 * 60 * 1000;
    const navigate = useNavigate();

    useEffect(() => {
        const channel = new BroadcastChannel("last-active-channel");

        channel.onmessage = (event) => {
            setLastActive(new Date(event.data));
        };

        return () => {
            channel.close();
        };
    }, []);

    useEffect(() => {
        const handleActivity = () => {
            const currentTime = new Date().getTime();
            setLastActive(currentTime);

            const channel = new BroadcastChannel("last-active-channel");
            channel.postMessage(currentTime);
        };

        window.addEventListener("click", handleActivity);
        window.addEventListener("keypress", handleActivity);
        window.addEventListener("mousemove", handleActivity);

        return () => {
            window.removeEventListener("click", handleActivity);
            window.removeEventListener("keypress", handleActivity);
            window.removeEventListener("mousemove", handleActivity);
        };
    }, []);

    useEffect(() => {
        let timeoutId;

        const checkLogoutTimeout = () => {
            const now = new Date();
            const elapsed = now - lastActive;
            if (elapsed >= logoutTimeout) {
                localStorage.clear();
                navigate('/signin');
                alertModal("center", "warning", "Your session has expired", "button", "oh no!", 5000);
            } else {
                timeoutId = setTimeout(checkLogoutTimeout, logoutTimeout - elapsed);
            }
        };

        timeoutId = setTimeout(checkLogoutTimeout, logoutTimeout);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [lastActive, logoutTimeout, navigate]);

    return null;
};
export default LogoutTimer;

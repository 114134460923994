import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
// For Scss Files
import "./styles/style.scss";
import WrappedApp from "./App";

const container = document.getElementById("root");

const root = createRoot(container);

root.render(<WrappedApp />);

reportWebVitals();

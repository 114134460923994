import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { decrypt } from "../../utils/encodingdecoding";
import { Helmet } from "react-helmet";
import { getTheme } from "../../services/services";
// import Loader from "../partial/Loader";
const Header = ({ children }) => {
    const [state, setState] = useState({
        logo: "",
        uploadedSource: "",
        name: "",
    });
    const [themeState, setThemeState] = useState({
        theme: "",
    });
    // const [loader, setLoader] = useState(false);
    const { theme } = themeState;
    const { logo, uploadedSource, name } = state;

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            logo: decrypt(localStorage.getItem("logo")),
            uploadedSource: decrypt(localStorage.getItem("uploadedSource")),
            name: localStorage.getItem("name"),
        }));
    }, []);

    const handleLogout = () => {
        localStorage.clear();
    };

    const fetchThemeData = async () => {
        try {
            // setLoader(true);
            let tempToken = JSON.parse(localStorage.getItem("token_gen"));
            const res = await getTheme(tempToken.access_token);
            const selectedTheme = res.data.selectedTheme;
            const matchedTheme = res.data.themesData.find((theme) => theme.id === selectedTheme);

            if (matchedTheme) {
                const data = matchedTheme.body.colors;
                setThemeState((prev) => ({
                    ...prev,
                    theme: `body {
                            background: ${data?.body} ;
                            color: ${data?.text} ;
                        }

                        .btn.btn-primary {
                            background: ${data?.button?.background} ;
                            color: ${data?.button?.text} ;
                            border : 1px solid ${data?.button?.background}
                        }

                        .customer_portal_header {
                            background: ${data?.header?.background} ;
                            color: ${data?.header?.text} ;
                        }

                        .customer_portal .customer_portal__mainPage .customer_portal__content .customer_portal__headerbox .loyalty_points_box .loyalty_points_box_header button {
                            background: ${data?.button?.background} ;
                            color: ${data?.button?.text} ;
                            
                            &:hover {
                                background-color: #2056a5;
                                color: ${data?.button?.text};
                                border-color: #2056a5;
                            }
                        }

                        .customer_portal .customer_portal__mainPage .customer_portal__content .tabs_box .tab-content .tabs_row .overview_box {
                            background: ${data?.overviewData?.background} ;
                        }
                        
                        .customer_portal .customer_portal__mainPage .customer_portal__content .tabs_box .tab-content .tabs_row .overview_box h6 {
                            color: ${data?.overviewData?.text} ;
                        }

                        .customer_portal .customer_portal__mainPage .customer_portal__content .tabs_box .tab-content .tabs_row .overview_box.drk {
                            background: ${data?.overviewCampaign?.background} ;
                        }

                        .customer_portal .customer_portal__mainPage .customer_portal__content .tabs_box .tab-content .tabs_row .overview_box.drk h6, .customer_portal .customer_portal__mainPage .customer_portal__content .tabs_box .tab-content .tabs_row .overview_box.drk p {
                            color: ${data?.overviewCampaign?.text} ;
                        }

                        .customer_portal .customer_portal__mainPage .customer_portal__content .tabs_box ul li button {
                             background: ${data?.button?.background} ;
                             color: ${data?.button?.text} ;
                             &.active {
                                 background-color: #2056a5;
                                 color: ${data?.button?.text};
                                 border-color: #2056a5;
                                &:hover {
                                     background-color: #2056a5;
                                     border-color: #2056a5;
                                }
                            }
                            &:hover {
                                 background-color: #2056a5;
                                 color: ${data?.button?.text};
                                 border-color: #2056a5;
                            }
                        }

                        .customer_portal .customer_portal__footer .footer__box {
                            background: ${data?.footer} ;
                        }
                        
                        .customer_portal .customer_portal__mainPage .customer_portal__content .customer_portal__headerbox .loyalty_points_box .points_expiring_box {
                            background: ${data?.div?.background} ;
                            color: ${data?.div?.text} ;
                        }
                        // .customer_portal .customer_portal__mainPage .customer_portal__content .share_box {
                        //     background: ${data?.div?.background} ;
                        //     color: ${data?.div?.text} ;
                        // }

                        // .customer_portal .customer_portal__mainPage .customer_portal__content .customer_portal__headerbox .loyalty_points_box {
                        //     background: ${data?.div?.background} ;
                        // }

                        button.rewardsbtn.active {
                            background: ${data?.div?.background};
                        }

                        // .customer_portal .customer_portal__mainPage .customer_portal__content .tabs_box .tab-content .tabs_row .overview_box.drk {
                        //     background: ${data?.div?.background};
                        // }

                        .customer_portal .customer_portal__mainPage .customer_portal__content .customer_portal__headerbox .loyalty_points_box__right__info h6 span.counter {
                            color: ${data?.div?.background};
                        }

                        .customer_portal .customer_portal__mainPage .customer_portal__content .customer_portal__headerbox .points_expiring_box .points_expiring_box__icon svg {
                            fill: ${data?.div?.background};
                        }

                        .customer_portal .customer_portal__footer .footer__box .footer_links ul li a {
                            color: ${data?.link?.text} ;
                        }
                        `,
                }));
            }
            // setLoader(false);
        } catch (error) {
            // setLoader(false);
        }
    };

    useEffect(() => {
        fetchThemeData();
    }, []);
    return (
        <>
            {/* {loader && <Loader />} */}
            <Helmet>
                <style>{theme}</style>
            </Helmet>
            <div className="customer_portal_header">
                <Link className="link" to="customer-portal" state={{ home: true, choose_rewards: false }}>
                    <figure>{logo ? <img src={logo} alt="logo" width="120" loading="lazy" /> : <h3>{uploadedSource}</h3>}</figure>
                </Link>
                <div className="dashboard__heading">
                    <h6>{name && `Hi, ${name}`}</h6>
                    <h6>
                        <Link className="logoutBtn" to={'/signin'} onClick={handleLogout}>
                            <i className="fa fa-power-off"></i>Logout
                        </Link>
                    </h6>
                </div>
            </div>
            <div className="customer_portal">{children}</div>
        </>
    );
};

export default Header;

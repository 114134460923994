import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { backArrow } from '../../Image/Index';
import { transactionRedeem } from '../../services/services';
import { getDate } from '../../utils/helper';
import Loader from '../partial/Loader';
import swal from "sweetalert";

function RecieptResponse() {
    const location = useLocation();
    const navigate = useNavigate(); 
    const [loader, setLoader] = useState(false);
    const response = location?.state.data;
    console.log(response)

    const redeemGiftCard = async (detail) => {
        setLoader(true);
        const customerCode = localStorage.getItem("c_code");
      
        const redeemBody = {
            customer: { customercode: customerCode },
            redeemDetail: { walletType: "point", values: Math.ceil(response[0]?.subTotal / 4), transactionType: "redeem", transactionDate: getDate(new Date(), "YYYY-MM-DD") },
        };
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const redeemRes = await transactionRedeem(token.access_token, JSON.stringify(redeemBody));
        if (redeemRes?.message === "Request processed successfully.") {
            swal({
                position: "center",
                icon: "success",
                title: "transaction process complete succesfully",
                // timer: 2000,
                button: "OK",
            }).then(function () {
                navigate("/customer-portal");
            });
        setLoader(false);
    }
    else{
       
            swal({
                position: "center",
                icon: "error",
                 timer: 5000,
                title: "Process is not complete Try again ",
                button: "OK",
            })
            setLoader(false);
    }
}
  return (
    <div>
        {loader && <Loader />}
      
           
        <div style={{justifyContent:"center",alignItems:"center",display:"flex",marginTop:"10px"}}>
        <h5>Receipt Confirmation</h5>
        </div>
     
      {response?.length > 0 && (
                <div style={{ margin: '20px' }}>
                  <table style={{ marginTop: "10px", width: "100%" }}>
                    <thead>
                      <tr>
                        <th style={{ border: '1px solid black', padding: '8px' }}>Item</th>
                        <th style={{ border: '1px solid black', padding: '8px' }}>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {response[0]?.items?.map((item) => (
                       
                        <tr>
                          <td style={{ border: '1px solid black', padding: '8px' }}>{item?.name}</td>
                          <td style={{ border: '1px solid black', padding: '8px' }}>{item?.price}</td>
                        </tr>
                        
                      ))}

<tr>
                          <td style={{ border: '1px solid black', padding: '8px',fontWeight:"700" }}>Total</td>
                          <td style={{ border: '1px solid black', padding: '8px',fontWeight:"700" }}>{response[0]?.subTotal}</td>
                        </tr>
                    </tbody>
                  </table>
                  <div className='text-center mt-3'>
                    <button className='rds' onClick={redeemGiftCard}>Process</button>
                    <Link to="/customer-portal"><button className='mll-5 rds'>Cancel</button>
                    </Link>
                  </div>
          
                </div>
              )}
    </div>
  )
}

export default RecieptResponse

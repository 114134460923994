import jwt_decode from "jwt-decode";
import { baseURL } from "./url";

export const isTokenExpired = (token) => {
    if (!token) return new Promise((resolve) => resolve(false));
    const now = new Date();
    if (token && jwt_decode(token)) {
        const expiry = jwt_decode(token);
        return new Promise((resolve) => resolve(now.getTime() / 1000 + 60 < expiry.exp));
    }
    return new Promise((resolve) => resolve(false));
};

export const getNewAuthToken = async () => {
    let body = {
        clientid: localStorage.getItem("clientid"),
        grant_type: "client_credentials",
        clientsecret: localStorage.getItem("clientsecret"),
    };
    try {
        const fetchResponse = await fetch(baseURL + "internaltoken", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (err) {
        return new Promise((resolve) => resolve(null));
    }
};

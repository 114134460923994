import React, { useEffect, useState } from "react";
import RedeemCard from "./RedeemCard.js";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { getBanner, getCustomerGiftcard, voucherTemplateDetails } from "../../services/services.js";
import Loader from "../partial/Loader.js";
import { voucher, giftCard, backArrow } from "../../Image/Index.js";
import RedeemCard1 from "./RedeemCard1.js";
import { decrypt } from "../../utils/encodingdecoding.js";
import Slider from "react-slick";
import Footer1 from "./Footer1.js";
import { getProfileData } from "../../services/auth.js";

const ChooseRewards1 = () => {
    const navigate = useNavigate();
    const [bannerData, setBannerData] = useState([]);
    const [socialMedia, setSocialMedia] = useState([]);
    const dataName = decrypt(localStorage.getItem("uploadedSource"));
    const location = useLocation();

    // const walletsDetail = location?.stateRewards?.walletsDetail
    const [stateRewards, setStateRewards] = useState({
        template: [],
        walletsDetail: {},
    });
    // console.log(walletsDetail)

    const [loader, setLoader] = useState(true);
    const [rewardState, setRewardState] = useState({
        giftCards: [],
        detail: "",
        type: "",
    });
    const [showDetail, setShowDetail] = useState(false);
    const [selectedValue, setSelectedValue] = useState();

    useEffect(() => {
        const fetch = async () => {
            setLoader(true);
            let tempTemplate = [];
            const token = JSON.parse(localStorage.getItem("token_gen"));
            await voucherTemplateDetails(token?.access_token)
                .then((res) => {
                    if (res?.data) {
                        const currentDate = new Date();
                        res?.data?.map((e) => e.isActive && new Date(e.voucherVisibilityDate) <= currentDate && tempTemplate.push(e));
                    }
                    setStateRewards((prevState) => ({
                        ...prevState,

                        template: tempTemplate,
                        walletsDetail: location?.stateRewards?.walletsDetail,
                    }));
                })
                .catch(() => {});

            const id = localStorage.getItem("Id");
            await getCustomerGiftcard(id)
                .then((res) => {
                    setRewardState((prevState) => ({
                        ...prevState,
                        giftCards: res?.data,
                    }));
                })
                .catch(() => {});
            setLoader(false);
        };
        fetch();
    }, []);

    const [data, setData] = useState();

    const fetchData = async () => {
        setLoader(true);
        const merchcode = localStorage.getItem("Id");
        let token = await JSON.parse(localStorage.getItem("token_gen"));
        const response = await getProfileData(token?.access_token, merchcode);
        window.scrollTo(0, 0);
        setData(response);
        // setLoader(false)
    };
    useEffect(() => {
        fetchData();
    }, []);
    const { template, walletsDetail } = stateRewards;

    const { giftCards, detail, type } = rewardState;

    useEffect(() => {
        if (selectedValue) {
            if (location?.stateRewards?.type === "internal") {
                setSelectedValue("internal");
            } else {
                setSelectedValue("external");
            }
        }
        // eslint-disable-next-line
    }, [location]);

    // useEffect(() => {
    //     const fetch = async () => {
    //         const id = localStorage.getItem("Id");
    //         const giftcard = await getCustomerGiftcard(id);

    //         setRewardState((prevState) => ({
    //             ...prevState,
    //             giftCards: giftcard?.data,
    //         }));

    //     };
    //     fetch();
    // }, []);

    const internalFun = () => {
        return (
            <div>
                {loader ? (
                    ""
                ) : template?.length > 0 ? (
                    <div className="internal_voucher_row">
                        {template
                            ?.filter((vou) => vou.title.includes("purchase"))
                            .map((vou, i) => (
                                <div className="internal_voucher_box" key={i}>
                                    <Link
                                        to=""
                                        stateRewards={{ selectedValue }}
                                        onClick={() => {
                                            setRewardState((prev) => ({
                                                ...prev,
                                                detail: { ...vou, earningValue: vou.earningValue },
                                                type: "voucher",
                                            }));
                                            setShowDetail((prev) => !prev);
                                        }}
                                    >
                                        <div className="internal_voucher_ibox">
                                            <img src={vou?.imageUpload ? vou.imageUpload : voucher} alt="" />
                                        </div>
                                        <div className="internal_voucher_cbox">
                                            <h5>{vou.name}</h5>
                                            <p dangerouslySetInnerHTML={vou.description ? { __html: vou.description } : null}></p>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                    </div>
                ) : (
                    <h6 className="text-center">No Record Found</h6>
                )}
            </div>
        );
    };

    const externalFun = () => {
        return (
            <div>
                {loader ? (
                    ""
                ) : giftCards?.length > 0 ? (
                    <div className="internal_giftcard_row">
                        {giftCards?.map((ele, index) => (
                            <div className="internal_giftcard_box" key={index}>
                                <Link
                                    to=""
                                    stateRewards={{ selectedValue }}
                                    onClick={() => {
                                        setRewardState((prev) => ({
                                            ...prev,
                                            detail: ele,
                                            type: "giftCard",
                                        }));
                                        setShowDetail(() => !showDetail);
                                    }}
                                >
                                    <div className="internal_giftcard_ibox">{<img src={ele?.image ? ele?.image : giftCard} alt={ele.title} />}</div>
                                    <div className="internal_giftcard_cbox">
                                        <h5>{ele.title}</h5>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                ) : (
                    <h6 className="text-center">No Record Found</h6>
                )}
            </div>
        );
    };
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };
    const logo = async () => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const id = localStorage.getItem("Id");
        await getBanner(token.access_token, id).then((res) => {
            setSocialMedia(res[0]?.socialMedia);
            setBannerData(res[0]?.bannerDetails);
            //   setLoader(false)
        });
    };

    useEffect(() => {
        logo();
    }, []);

    const handleSelect = (e) => {
        if (e.target.value === "internal") {
            setSelectedValue(e.target.value);
        } else if (e.target.value === "external") {
            setSelectedValue(e.target.value);
        } else {
            setSelectedValue("");
        }
    };

    return (
        <div>
            {loader && <Loader />}
            <div className="customer_portal__mainPage">
                <Slider {...settings}>
                    {bannerData.map((item, index) => (
                        <div className="hero-banner" key={index}>
                            <img src={item.blobFilePath} alt={`banner-${index}`} />
                        </div>
                    ))}
                </Slider>
                {!showDetail ? (
                    <div className="allCardContainer">
                        <div className="function_row">
                            <div className="function_back">
                                <Link className="backAro" onClick={() => navigate(-1)}>
                                    <img src={backArrow} alt="Back" height={16} /> &nbsp; Back
                                </Link>
                            </div>
                            <div className="d-flex justify-content-end align-items-center px-1">
                                <div className="chooseVc">Choose a Giftcard/Voucher</div>
                                <div>
                                    <select className="form-select form-select-sm" name="vouchers" id="vouchers" value={selectedValue} onChange={handleSelect}>
                                        <option value="all">All</option>
                                        <option value="internal">Local Merchant Voucher</option>
                                        <option value="external">International Voucher</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="allCardContainer__top">
                            {selectedValue === "internal" ? (
                                internalFun()
                            ) : selectedValue === "external" ? (
                                externalFun()
                            ) : (
                                <div>
                                    {loader ? (
                                        ""
                                    ) : (
                                        <h6 className="voucher-heading">
                                            <span>Local Merchant Voucher</span>
                                        </h6>
                                    )}
                                    {internalFun()}
                                    {loader ? (
                                        ""
                                    ) : (
                                        <h6 className="voucher-heading">
                                            <span>International Voucher</span>
                                        </h6>
                                    )}
                                    {externalFun()}
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <RedeemCard1 detail={detail} type={type} walletsDetail={walletsDetail} setShowDetail={setShowDetail} data={data} />
                )}
            </div>
            <div className="footer__Box">
                <div className="inner_footer">
                    <div className="inner_footerbox inner_footerbox_1">
                        <h4>Quick Links</h4>
                        <ul>
                            <li>
                                <Link to="/customer-portal">Home</Link>
                            </li>
                            <li>
                                <Link to="/Choose-Rewards">Rewards</Link>
                            </li>
                            <li>
                                <Link to="/customer-portal?tab=history">History</Link>
                            </li>
                            <li>
                                <Link to="/customer-portal?tab=voucher">Vouchers</Link>
                            </li>
                            <li>
                                <Link to="/customer-portal?tab=giftcard">Gift Cards</Link>
                            </li>
                        </ul>
                    </div>
                    {data?.location?.length > 0 ? (
                        <div className="inner_footerbox inner_footerbox_2">
                            <h4>Our Location</h4>
                            <p>{data?.location}</p>
                        </div>
                    ) : null}
                    <div className="inner_footerbox inner_footerbox_3">
                        <h4>Contact Us</h4>
                        <ul>
                            <li>
                                <a>{data?.contactnumber}</a>
                            </li>

                            <li>
                                {" "}
                                <Link to={`mailto:${data?.email}`}>{data?.email}</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="social_links">
                        {socialMedia?.length &&
                            socialMedia.map((ele, i) => (
                                <span key={i}>
                                    {ele.socialMediaName === "facebook" && ele.status === true ? (
                                        <Link to={ele.socialMediaLink} className="social_links__icon facebook_ic">
                                            <i className="fa-brands fa-facebook-f"></i>
                                        </Link>
                                    ) : ele.socialMediaName === "instagram" && ele.status === true ? (
                                        <Link to={ele.socialMediaLink} className="social_links__icon instagram_ic">
                                            <i className="fa-brands fa-instagram"></i>
                                        </Link>
                                    ) : ele.socialMediaName === "linkedin" && ele.status === true ? (
                                        <Link to={ele.socialMediaLink} className="social_links__icon linkedin_ic">
                                            <i className="fa-brands fa-linkedin-in"></i>
                                        </Link>
                                    ) : ele.socialMediaName === "twitter" && ele.status === true ? (
                                        <Link to={ele.socialMediaLink} className="social_links__icon twitter_ic">
                                            <i className="fa-brands fa-twitter"></i>
                                        </Link>
                                    ) : null}
                                </span>
                            ))}
                    </div>
                </div>
                <div className="copyright_box">
                    <div className="copyright_innerbox">
                        <div className="copyright_textbox">
                            <ul>
                                <li>© 2024 The {data?.companyname}. All Rights Reserved</li>
                                {/* <li><a href="https://maritimefinancial.com/privacy-policy/" target="_blank">Privacy Policy</a></li> */}
                            </ul>
                        </div>
                        <div className="copyright_arrowbox">
                            <a href="#" id="totopbutton">
                                <span></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChooseRewards1;

import React, { useState, useRef, useEffect } from "react";
import { Modal, CloseButton } from "react-bootstrap";
import { receiptFilePath, fileUpload, transaction, merchantSchema } from "../../services/services";
import Loader from "../partial/Loader";
import { alertModal } from "../../utils/helper";
import { useNavigate } from "react-router-dom";

const UploadModal = (props) => {
    const receiptRef = useRef();
    const navigate = useNavigate(); 
    const [loader, setLoader] = useState(false);
    const { show, close } = props;
    const [state, setState] = useState({
        selectedFile: "",
        size: false,
        successMsg: false,
        receiptRes: "",
    });
    const { selectedFile, size, successMsg, receiptRes } = state;

    useEffect(() => {
        const fetch = async () => {
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const id = localStorage.getItem("Id");
            const schemaRes = await merchantSchema(token.access_token, id);
            if (schemaRes.message === "success") {
                let schemaCode;
                schemaRes.data.filter((element) => {
                    if (element.schemaName !== "Referral") {
                        schemaCode = element.schemaCode;
                    }
                    return schemaCode;
                });
                const body = {
                    amount: receiptRes[0]?.total,
                    email: props.email,
                    schemacode: schemaCode,
                };
                const transRes = await transaction(token?.access_token, body);
                if (transRes.message === "Request processed successfully.") {
                    alertModal("center", "success", "Receipt upload successfully", "button", "OK", 5000);
                    setLoader(false);
                } else {
                    alertModal("center", "error", "Receipt upload failed", "button", "OK", 5000);
                }
                setLoader(false);
            } else {
                alertModal("center", "error", "Something went wrong please try later", "button", "OK", 5000);
                setLoader(false);
            }
        };
        if (receiptRes && receiptRes[0]?.total > 0) {
            fetch();
        } else {
            if (Array.isArray(receiptRes)) {
                alertModal("center", "warning", "Please upload a receipt", "button", "OK", 5000);
                setLoader(false);
            }
        }
        //eslint-disable-next-line
    }, [receiptRes]);

    const handleFile = (e) => {
        if (e.target.files[0] && e.target.files[0]?.size > 1 * 1024 * 1024) {
            setState({ size: true });
        } else {
            const file = e.target.files[0];
            setState((prev) => ({
                ...prev,
                size: false,
                selectedFile: file,
            }));
        }
    };

    const Submit = async () => {
        const token = JSON.parse(localStorage.getItem("token_gen"));
        if (!size) {
            setLoader(true);
            const fileRes = await fileUpload(token?.access_token, selectedFile);
            if (fileRes.message === "success") {
                receiptFilePath({ receiptURL: fileRes.data.blobFilePath })
                    .then((res) => res.json())
                    .then((data) => {
                        setState((prev) => ({
                            ...prev,
                            receiptRes: data?.data,
                        }));
                        navigate('/reciept', { state: data });

                        setLoader(false);
                       
                        // close(); 
                        receiptRef.current.value = "";
                    })
                    .catch((err) => {
                        alertModal("center", "error", "Something went wrong please try again.", "button", "OK", 5000);
                        receiptRef.current.value = "";
                        setLoader(false);
                    });
            } else {
                alertModal("center", "error", "Something went wrong please try again.", "button", "OK", 5000);
                receiptRef.current.value = "";
                setLoader(false);
            }
        }
    };
    
    return (
        <>
        {loader && <Loader />}
        <Modal show={show} onHide={close} centered>
            <Modal.Header>
                <Modal.Title style={{ fontSize: "20px" }}>Upload Receipt</Modal.Title>
                <CloseButton onClick={close} variant="white" />
            </Modal.Header>
            <Modal.Body style={{ position: "relative", minHeight: "85px" }}>
                <p>Upload receipt to get reward</p>

                {successMsg ? (
                    <span className="successMessage">{successMsg}</span>
                ) : (
                    <form className="customForm">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <input className="form-control" type="file" ref={receiptRef} accept={".jpeg, .jpg, .pdf,.PNG"} required onChange={handleFile} />
                                {size && <span className="errorMessage">File size not more than 1 MB</span>}
                            </div>
                        </div>
                    </form>
                )}
            </Modal.Body>
            {!successMsg && (
                <Modal.Footer>
                    <button type="submit" className="btn btn-primary" disabled={selectedFile ? false : true} onClick={Submit}>
                        Upload Receipt
                    </button>
                </Modal.Footer>
            )}
        </Modal>
    </>
    );
};

export default UploadModal;

import React, { useState } from "react";
import OtpInput from "react-otp-input";
import AuthenticationSliderLeft from "./common/AuthenticationSliderLeft";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../partial/Loader";
import { Internal_Token } from "../../services/auth";
import { mobileOtpVerification, otpVerification } from "../../services/services";
import { alertModal } from "../../utils/helper";
import { decrypt } from "../../utils/encodingdecoding";

const Otp = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [otp, setOtp] = useState("");
    const mobileotp = false;

    const handleMobileOtp = async () => {
        const mobile = decrypt(localStorage.getItem("mobile"));
        const body = {};
        const res = await mobileOtpVerification(otp, `+91${mobile}`, body);
        return res;
    };
    const handleEmailOtp = async () => {
        let tokenBody = {
            clientid: localStorage.getItem("client_id"),
            grant_type: "client_credentials",
            clientsecret: localStorage.getItem("client_secret"),
        };
        const token = await Internal_Token(tokenBody);
        const mobile = localStorage.getItem("mobile");
        const body = {};
        const response = await otpVerification(token?.access_token, mobile, otp, body);
        return response;
    };

    const handleClick = async () => {
        setLoader(true);
        let response;
        if (mobileotp) {
            response = await handleMobileOtp();
        } else {
            response = await handleEmailOtp();
        }

        if (response.message === "OTP verification successful." || response.type === "success") {
            alertModal("center", "success", "Customer Registered Successfully", "button", "ok", 5000);
            localStorage.removeItem("mobile");
            navigate("/rewards_signin");
            setLoader(false);
        } else {
            alertModal("center", "warning", "OTP does not match", "button", "ok", 5000);
            setLoader(false);
        }
        setLoader(false);
    };

    return (
        <section className="section signUpPage newStyle">
            {loader && <Loader />}
            <div className="signUpPage__main">
                <AuthenticationSliderLeft response={location.state.responseData} />
                <div className="signUpPage__right">
                    <div className="signUpPage__right__inside">
                        <div className="signUpPage__heading">
                            <figure>{location.state.logo ? <img src={location.state.logo} alt="logo" /> : <h1>{location.state.uploadSource}</h1>}</figure>
                            <h1>Great to see you here!</h1>
                        </div>

                        <div className="signUpPage__form">
                            <OtpInput value={otp} onChange={setOtp} numInputs={4} renderSeparator={<span>-</span>} renderInput={(props) => <input {...props} />} />
                            <button onClick={handleClick}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Otp;

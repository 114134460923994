import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { decrypt } from "../../utils/encodingdecoding";
import moment from "moment";
import { alertModal } from "../../utils/helper";
import { ValidatorForm } from "react-form-validator-core";
import Loader from "../partial/Loader";
import PasswordValidator from "../partial/PasswordValidator";
import { Internal_Token, resetPassword } from "../../services/auth";
import { getCustomer } from "../../services/services";

const ResetPswd = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [state, setState] = useState({
        isRevealPassword: false,
        isConfirmPassword: false,
        passwordFocus: false,
        confirmFocus: false,
        password: "",
        cnfPassword: "",
        linkExpired: "",
        email: "",
        merchantId: "",
    });
    const { isRevealPassword, isConfirmPassword, passwordFocus, confirmFocus, password, cnfPassword, linkExpired, email, merchantId } = state;

    useEffect(() => {
        ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
            if (value !== password) {
                return false;
            }
            return true;
        });
        // eslint-disable-next-line
    }, [cnfPassword, password]);

    const sessionDiff = () => {
        const queryString = location.search;
        const urlParams = new URLSearchParams(queryString);
        const email = urlParams.get("email");
        let session = urlParams.get("session");
        session = parseInt(decrypt(session));
        let id = urlParams.get("id");
        id = decrypt(id);
        const currentDate = parseInt(moment().format("mm"));
        const diff = currentDate - session;
        if (diff >= 0 && diff <= 15) {
            setState({ ...state, email: email, linkExpired: false, merchantId: id });
        } else {
            setState({ ...state, linkExpired: true });
        }
    };
    useEffect(() => {
        sessionDiff();
        // eslint-disable-next-line
    }, []);

    const onChange = (e) => {
        setState({ ...state, ...{ [e.target.name]: e.target.value } });
    };

    const onSubmit = async () => {
        setLoader(true);
        const body = { password: password };
        let tokenBody = {
            clientid: localStorage.getItem("client_id"),
            grant_type: "client_credentials",
            clientsecret: localStorage.getItem("client_secret"),
        };
        const token = await Internal_Token(tokenBody);
        const Id = localStorage.getItem("Id");
        const cusCount = await getCustomer(token.access_token, 1, 1, Id);
        const cusCode = await getCustomer(token.access_token, 1, cusCount?.totalCount, Id);
        const customerCode = cusCode?.data?.find((item) => item.email === decrypt(email))?.customercode;
        const res = await resetPassword(token?.access_token, customerCode, body);
        if (res.message === "Request processed successfully.") {
            navigate('/signin');
            alertModal("center", "success", "Password reset successfully", "button", "OK", 5000);
        } else {
            alertModal("center", "error", res.message, "button", "OK", 5000);
        }
        setLoader(false);
    };

    return (
        <div>
            {typeof linkExpired === "boolean" ? (
                <section className="section signUpPage">
                    {loader && <Loader />}
                    <div className="signUpPage__main">
                        <div className="signUpPage__right resetPass">
                            <div className="signUpPage__heading">
                                <figure>{localStorage.getItem("blob") ? <img src={localStorage.getItem("blob")} alt="logo" /> : <h1>{decrypt(localStorage.getItem("uploadedSource"))}</h1>}</figure>
                                <h1>Reset Password</h1>
                            </div>
                            <div className="signUpPage__form">
                                {linkExpired ? (
                                    <p className="linkExp">Reset Password link expired</p>
                                ) : (
                                    <ValidatorForm className="signUpPage__formSection" onSubmit={(e) => onSubmit(e)}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group withIcon">
                                                    <span className="passwordToggle">{passwordFocus ? <img className="onFocus" title={isRevealPassword ? "Hide password" : "Show password"} src={isRevealPassword ? "/Images/eyeUnseenFocus.png" : "/Images/eyeSeenFocus.png"} alt="eye" onClick={() => setState((prev) => ({ ...prev, isRevealPassword: !isRevealPassword }))} /> : <img title={isRevealPassword ? "Hide password" : "Show password"} src={isRevealPassword ? "/Images/eyeUnseen.png" : "/Images/eyeSeen.png"} alt="eye" onClick={() => setState((prev) => ({ ...prev, isRevealPassword: !isRevealPassword }))} />}</span>
                                                    <PasswordValidator placeholder="New Password" className="form-control" name="password" minLength="8" maxLength="20" value={password} autoComplete="new-password" type={isRevealPassword ? "text" : "password"} onChange={onChange} validators={["required", "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,20}$"]} errorMessages={["Please enter password.", "condition"]} />
                                                </div>
                                                <div className="form-group withIcon">
                                                    <span className="passwordToggle">{confirmFocus ? <img className="onFocus" title={isConfirmPassword ? "Hide password" : "Show password"} src={isConfirmPassword ? "/Images/eyeUnseenFocus.png" : "/Images/eyeSeenFocus.png"} alt="eye" onClick={() => setState((prev) => ({ ...prev, isConfirmPassword: !isConfirmPassword }))} /> : <img title={isConfirmPassword ? "Hide password" : "Show password"} src={isConfirmPassword ? "/Images/eyeUnseen.png" : "/Images/eyeSeen.png"} alt="eye" onClick={() => setState((prev) => ({ ...prev, isConfirmPassword: !isConfirmPassword }))} />}</span>
                                                    <PasswordValidator placeholder="Confirm Password" className="form-control" name="cnfPassword" minLength="8" maxLength="20" value={cnfPassword} type={isConfirmPassword ? "text" : "password"} onChange={onChange} validators={["required", "isPasswordMatch"]} errorMessages={[" Please enter confirm password.", "Password Mismatch."]} />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-primary btn-lg w-100">
                                                        Reset Password
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </ValidatorForm>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                ""
            )}
        </div>
    );
};

export default ResetPswd;

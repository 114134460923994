import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { alertModal, getDate } from "../../utils/helper";
import { giftCard, voucher, loyalCustomer, DummyCampaign } from "../../Image/Index";
import RedeemModal from "../partial/RedeemModal";
import { getTransaction, merchantSchema, transaction, customerReview, getQualifiedCamp } from "../../services/services";
import Loader from "../partial/Loader";
import { decrypt } from "../../utils/encodingdecoding";

const Home = ({ state, setState }) => {
    const [toggle, setToggle] = useState({
        isActive: false,
        index: null,
    });
    const [loader, setLoader] = useState(false);
    const [homeState, setHomeState] = useState({
        showModal: false,
        history: [],
        enableCamp: [],
        linkUrl: [
            {
                camp: [],
                url: "",
            },
        ],
        disableCamp: [],
        enableRevCamp: [],
        disableRevCamp: [],
        review: [
            {
                camp: [],
                url: "",
                newReview: "",
                error: false,
            },
        ],
        qualifiedCamp: [],
    });
    const { showModal, history, enableCamp, enableRevCamp, disableRevCamp, error, disableCamp, linkUrl, review, qualifiedCamp } = homeState;
    const { isActive, index } = toggle;
    const { email, flag, walletsDetail, campaigns, activityLogs, rewards, orderList, walletSummary, transactions } = state;

    const toggleActive = (ind) => {
        if (index === ind) {
            setToggle({ ...toggle, index: ind, isActive: !isActive });
        } else {
            setToggle({ ...toggle, index: ind, isActive: true });
        }
    };

    useEffect(() => {
        const temp = activityLogs?.reverse() && activityLogs?.filter((ele) => ele.transactionType && ele);
        setHomeState((prev) => ({ ...prev, history: temp }));
    }, [activityLogs]);

    useEffect(() => {
        const fetch = async () => {
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const customerCode = localStorage.getItem("c_code");
            const res = await getQualifiedCamp(token.access_token, customerCode);
            if (res.message === "success") {
                setHomeState((prev) => ({
                    ...prev,
                    qualifiedCamp: res.data,
                }));
            }
        };
        fetch();
    }, []);

    useEffect(() => {
        let url = [];
        let reviewurl = [];
        let enbCamp = [];
        let disCamp = [];
        let enbRevCamp = [];
        let disRevCamp = [];

        campaigns.length &&
            campaigns?.filter((camp) => {
                return camp.earningRule.rules.some((ele) => {
                    return ele.infixRule.some((rule) => {
                        return rule?.field === "reviewurl"
                            ? reviewurl.push({
                                camp: camp,
                                url: rule.value[0],
                                newReview: "",
                                error: false,
                            }) && enbRevCamp.push(camp.code)
                            : rule.field?.toLowerCase() === "socialmedia" &&
                            ele.infixRule.some((nextRule) => {
                                if (nextRule.field?.toLowerCase() === "socialmediaurl") {
                                    url.push({
                                        camp: camp,
                                        url: nextRule.value[0],
                                    });
                                    enbCamp.push(camp.code);
                                }
                                return null;
                            });
                    });
                });
            });

        transactions?.length &&
            transactions?.filter((trans) => {
                if (Array.isArray(trans.qualifiedCampaign) && trans.qualifiedCampaign.length) {
                    campaigns?.length &&
                        campaigns.filter((camp) => {
                            if (camp.code === trans.qualifiedCampaign[0].campaignId) {
                                camp.earningRule.rules.some((ele) => {
                                    return ele.infixRule.some((rule) => {
                                        return (
                                            rule.field?.toLowerCase() === "socialmedia" &&
                                            ele.infixRule.some((nextRule) => {
                                                if (nextRule.field?.toLowerCase() === "socialmediaurl") {
                                                    if (!disCamp.includes(camp.code)) {
                                                        enbCamp = enbCamp.filter(function (item) {
                                                            return item !== camp.code;
                                                        });
                                                        disCamp.push(camp.code);
                                                    }
                                                }
                                                return null;
                                            })
                                        );
                                    });
                                });
                            }
                            return null;
                        });
                }
                return null;
            });
        campaigns.length &&
            campaigns?.filter((camp) => {
                if (qualifiedCamp?.length) {
                    qualifiedCamp.filter((qual) => {
                        if (camp.code === qual.campaignId) {
                            disRevCamp.push(camp.code);
                            return disRevCamp;
                        }
                    });
                }
                return null;
            });

        setHomeState((prev) => ({
            ...prev,
            enableCamp: enbCamp,
            disableCamp: disCamp,
            linkUrl: url,
            review: reviewurl,
            enableRevCamp: enbRevCamp,
            disableRevCamp: disRevCamp,
        }));
    }, [campaigns, transactions, qualifiedCamp]);

    useEffect(() => {
        let disRevCamp = [];
        if (enableRevCamp?.length > 0) {
            enableRevCamp?.map((ele) => {
                if (qualifiedCamp?.length) {
                    qualifiedCamp.filter((qual) => {
                        if (ele === qual.campaignId) {
                            disRevCamp.push(ele);
                            return disRevCamp;
                        }
                    });
                }
                return null;
            });
        }
        setHomeState((prev) => ({
            ...prev,
            disableRevCamp: [...prev.disableRevCamp, ...disRevCamp],
        }));
    }, [enableRevCamp]);

    const handleSocialCamp = (camp) => {
        let transAmt = {};
        camp.earningRule.rules[0].infixRule.forEach((ele) => ele.field && (ele.field === "amount" ? (transAmt = { ...transAmt, [ele.field]: parseInt(ele.value[0]) }) : (transAmt = { ...transAmt, [ele.field]: ele.value[0] })));
        const fetch = async () => {
            setLoader(true);
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const id = localStorage.getItem("Id");
            const customerCode = localStorage.getItem("c_code");
            const schemaRes = await merchantSchema(token.access_token, id);
            if (schemaRes.message === "success") {
                let schemaCode;
                schemaRes.data.filter((element) => {
                    if (element.schemaName !== "Referral") {
                        schemaCode = element.schemaCode;
                    }
                    return schemaCode;
                });
                const body = { ...transAmt, schemacode: schemaCode, email: email };
                setLoader(false);
                const transRes = await transaction(token?.access_token, body);
                if (transRes.message === "Request processed successfully.") {
                    const id = localStorage.getItem("Id");
                    let transaction = await getTransaction(token.access_token, customerCode, id);
                    setState((prev) => ({ ...prev, transactions: transaction }));
                    // alertModal("center", "success", `${transRes.message}`, "button", "OK", 5000);
                    setLoader(false);
                } else {
                    // alertModal("center", "error", `${transRes.message}`, "button", "OK", 5000);
                    setLoader(false);
                }
            } else {
                alertModal("center", "error", "Something went wrong please try later", "button", "OK", 5000);
                setLoader(false);
            }
        };

        linkUrl?.map((ele) => {
            if (ele.camp.code === camp.code) {
                fetch();
                window.open(`${ele.url.toLowerCase()}`, "_blank");
            }
            return null;
        });
    };

    const handleReviewTrans = (ele) => {
        const fetch = async (url) => {
            setLoader(true);
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const id = localStorage.getItem("Id");
            const customerCode = localStorage.getItem("c_code");
            const body = {
                id: "",
                merchantCode: id,
                customercode: customerCode,
                campaignCode: ele.code,
                link: url,
                discriminator: "",
                status: true,
                other: "",
                other1: "",
                createdAt: "",
                updatedAt: "",
                remarks: "",
            };
            const cusReview = await customerReview(token?.access_token, body);
            if (cusReview.message === "success") {
                const id = localStorage.getItem("Id");
                let transaction = await getTransaction(token.access_token, customerCode, id);
                setState((prev) => ({ ...prev, transactions: transaction }));
                alertModal("center", "success", `Review saved successfully`, "button", "OK", 5000);
                setLoader(false);
            } else {
                alertModal("center", "error", `${cusReview.message}`, "button", "OK", 5000);
                setLoader(false);
            }

            setLoader(false);
        };
        if (review?.length) {
            review?.forEach((rev) => {
                if (rev.camp.code === ele.code) {
                    if (rev.newReview === "") {
                        setHomeState((prev) => ({
                            ...prev,
                            review: prev.review.map((rev) => {
                                if (rev.camp.code === ele.code) {
                                    return {
                                        ...rev,
                                        error: true,
                                    };
                                } else return rev;
                            }),
                        }));
                    } else {
                        fetch(rev.newReview);
                    }
                }
            });
        }
    };

    const handleReviewCamp = (ele) => {
        if (review?.length) {
            review?.forEach((rev) => {
                if (rev.camp.code === ele.code) {
                    const width = window.screen.width / 1.5;
                    const height = window.screen.height / 1.5;
                    const left = (window.screen.width - width) / 2;
                    const top = (window.screen.height - height) / 2;
                    window.open(rev.url, "", `width=${width},height=${height},left=${left},top=${top}`);
                }
            });
        }
    };

    const reviewChange = (e, ele) => {
        setHomeState((prev) => ({
            ...prev,
            review: prev.review.map((rev) => {
                if (rev.camp.code === ele.code) {
                    return {
                        ...rev,
                        newReview: e.target.value,
                        error: false,
                    };
                } else return rev;
            }),
        }));
    };

    return (
        <>
            {loader && <Loader />}
            <Tabs id="customerViewPointsTabs">
                <Tab eventKey="Overview">
                    {flag ? (
                        ""
                    ) : (
                        <div>
                            <div className="tabs_row cardSaprate">
                                <div className="report_item_container">
                                    <div className="report_item">
                                        <figure className="report_item__icon"></figure>
                                        <figcaption className="report_item__info">
                                            <h4>Available Reward Points</h4>
                                            <h5> {walletsDetail?.availableValue}</h5>
                                        </figcaption>
                                    </div>

                                    <div className="report_item">
                                        <figure className="report_item__icon"></figure>
                                        <figcaption className="report_item__info">
                                            <h4>Available Reward Points for Redemption </h4>
                                            <h5>{walletsDetail?.TotalavailableValue}</h5>
                                        </figcaption>
                                    </div>
                                    <div className="report_item">
                                        <figure className="report_item__icon"></figure>
                                        <figcaption className="report_item__info">
                                            <h4>Earned Value Redemption After Date</h4>
                                            <div className="report_item__info__clickable">
                                                <button className="earnedValueBtn" onClick={() => setHomeState((prev) => ({ ...prev, showModal: true }))}>
                                                    <span>{walletsDetail?.earnedValueRedemptionAfterDate}</span>
                                                    <span className="divider">|</span>
                                                    <i className="fa-solid fa-eye"></i>
                                                </button>
                                            </div>
                                        </figcaption>
                                    </div>

                                    <div className="report_item">
                                        <figure className="report_item__icon"></figure>
                                        <figcaption className="report_item__info">
                                            <h4>Total Earned Points</h4>
                                            <h5>{walletsDetail?.earnedValue}</h5>
                                        </figcaption>
                                    </div>
                                    <div className="report_item">
                                        <figure className="report_item__icon"></figure>
                                        <figcaption className="report_item__info">
                                            <h4>Total Expired Points</h4>
                                            <h5>{walletsDetail?.expiredValue} </h5>
                                        </figcaption>
                                    </div>
                                    <div className="report_item">
                                        <figure className="report_item__icon"></figure>
                                        <figcaption className="report_item__info">
                                            <h4>Total Redeemed Points</h4>
                                            <h5>{walletsDetail?.redeemValue}</h5>
                                        </figcaption>
                                    </div>
                                </div>
                            </div>

                            {campaigns?.length > 0 ? (
                                <div className="tabs_row">
                                    {campaigns?.map(
                                        (ele, i) =>
                                            ele.approvalStatus === 1 && (
                                                // <div className={"overview_box drk"} key={i}>
                                                <div className={enableRevCamp.includes(ele.code) ? "overview_box review" : enableCamp.includes(ele.code) ? "overview_box" : disableCamp.includes(ele.code) ? "overview_box blur" : "overview_box light"} key={i} onClick={() => handleSocialCamp(ele)}>

                                                    {/* <h6>{ele.name}</h6> */}
                                                    {
                                                        // ele?.tags?.length ? (
                                                        // ele?.tags?.map(
                                                        //     (tag) =>
                                                        //         tag.TagKey === "Promotion" && (
                                                        //             <div>
                                                        //                 <p dangerouslySetInnerHTML={ele.description ? { __html: ele.description } : null}></p>
                                                        //                 <button onClick={() => handleReviewCamp(ele)}>Click here to write review</button>
                                                        //                 <br />
                                                        //                 <button onClick={() => handleTrans(ele)}>Enter new review</button>
                                                        //                 <br />
                                                        //                 <input placeholder="review link" onChange={(e) => reviewChange(e, ele)} />
                                                        //             </div>
                                                        //         )
                                                        // )
                                                        review?.some((rev) => rev?.camp?.code === ele.code) ? (
                                                            review?.map(
                                                                (rev, ind) =>
                                                                    rev.camp.code === ele.code && (
                                                                        <div key={ind}>
                                                                            {ele.description.includes("<img") ? <p className="card-item-center-block" dangerouslySetInnerHTML={ele.description ? { __html: ele.description } : null}></p> :
                                                                                <>
                                                                                    <div className="card-item-center-block other">
                                                                                        <div className="internalText">
                                                                                            <h5>{ele.name}</h5>
                                                                                            <p dangerouslySetInnerHTML={ele.description ? { __html: ele.description } : null}></p>
                                                                                        </div>
                                                                                        <img src={DummyCampaign} alt="fig" />
                                                                                    </div>

                                                                                </>}

                                                                            <div className="mt-3">
                                                                                <button onClick={() => handleReviewCamp(ele)}>Click here to write review</button>

                                                                                <div className="urlBlock">
                                                                                    {/* <input placeholder="Enter review link" onChange={(e) => reviewChange(e, ele)} className="form-control" />
                                                                                    {disableRevCamp.includes(ele.code) ? <p>You have already rewarded from this campaign</p> : <button onClick={() => handleReviewTrans(ele)}>Submit</button>} */}
                                                                                    {disableRevCamp.includes(ele.code) ? (
                                                                                        <p className="rewarded">You have already rewarded from this campaign</p>
                                                                                    ) : (
                                                                                        <>
                                                                                            <input placeholder="Enter review link" onChange={(e) => reviewChange(e, ele)} className="form-control" />
                                                                                            <button onClick={() => handleReviewTrans(ele)}>Submit</button>
                                                                                        </>
                                                                                    )}
                                                                                </div>

                                                                                <p className="errorMsg">{rev.error && "Please enter review link"}</p>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                            )
                                                        ) : (
                                                            <>
                                                                {ele.description.includes("<img") ? <p className="card-item-center-block" dangerouslySetInnerHTML={ele.description ? { __html: ele.description } : null}></p> :
                                                                    <>
                                                                        <div className="card-item-center-block other">
                                                                            <div className="internalText">
                                                                                <h5>{ele.name}</h5>
                                                                                <p dangerouslySetInnerHTML={ele.description ? { __html: ele.description } : null}></p>
                                                                            </div>
                                                                            <img src={DummyCampaign} alt="fig" />
                                                                        </div>

                                                                    </>}
                                                                <div className="urlBlock">{disableRevCamp.includes(ele.code) && <p className="rewarded">You have already rewarded from this campaign</p>}</div>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            )
                                    )}
                                </div>
                            ) : (
                                <h6 className="text-center">No Record Found</h6>
                            )}
                        </div>
                    )}
                </Tab>
                {/* <Tab eventKey="History" title="History">
                    {history?.length ? (
                        <div className="history_row">
                            {history?.map((ele, i) => (
                                <div className="history_box" key={i}>
                                    <div className="history_details">
                                        <h5>Trans ID: {ele.transactionId}</h5>
                                        <p>{getDate(ele.transctionDate, "YYYY-MM-DD")}</p>
                                    </div>
                                    <div className="history_points">{ele?.transactionType?.toUpperCase() === "PURCHASE" ? <p className="success">{ele.value || !ele.voucherCode ? `${ele?.value} Points Earned` : ele.voucherCode && `${ele.valueType} Earned Worth ${ele.value}`}</p> : ele?.transactionType?.toUpperCase() === "REDEEM" ? <p className="redeemed">{ele?.value} Points Redeemed</p> : ele?.transactionType?.toUpperCase() === "VOUCHERPURCHASE" ? <p className="success"> Voucher Earned Worth {ele?.value} </p> : ele?.transactionType?.toUpperCase() === "REFUND" ? <p className="refunded">{ele?.value} Points Refunded</p> : null}</div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <h6 className="text-center"> No Record Found</h6>
                    )}
                </Tab> */}
                {/* <Tab eventKey="Rewards" title="Rewards">
                    {rewards?.voucherData?.length > 0 ? (
                        <div className="rewards_row">
                            {rewards?.voucherData.map((vou, i) => (
                                <div className="rewards_box" key={i}>
                                    <div className="rewards_ibox">
                                        {rewards?.templateImage.map(
                                            (template, i) =>
                                                template.templateCode === vou.voucherTemplateCode && (
                                                    <div key={i}>
                                                        <img src={template.templateImage ? template.templateImage : voucher} alt="rewards" />
                                                    </div>
                                                )
                                        )}
                                    </div>
                                    <div className="rewards_cbox">
                                        {rewards?.templateImage.map(
                                            (template, i) =>
                                                template.templateCode === vou.voucherTemplateCode && (
                                                    <div key={i}>
                                                        <div className="rewards_cbox">
                                                            <h4>{template.templateName}</h4>
                                                        </div>
                                                    </div>
                                                )
                                        )}
                                        <p>
                                            Voucher Code: <span>{vou.voucherCode}</span>
                                        </p>
                                        <p>
                                            Voucher Value: <span>{vou.earnedValue}</span>
                                        </p>
                                        <p>
                                            Expiry Date: <span>{getDate(vou.expiryDate, "YYYY-MM-DD")}</span>
                                        </p>
                                        <small>
                                            <span className="giftStar">*</span>Gift cards or vouchers once bought cannot be exchanged, refunded or cancelled
                                        </small>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <h6 className="text-center"> No Record Found</h6>
                    )}
                </Tab> */}
                {/* <Tab eventKey="Giftcards" title="Giftcards">
                    {orderList?.length ? (
                        <div className="giftcards_row">
                            {orderList?.map((ele, ind) =>
                                ele?.item_list.map((item) => (
                                    <div className={`giftcards_box ${index === ind && isActive === true ? "active" : ""}`} onClick={() => toggleActive(ind)} key={ind}>
                                        <div className="giftcards_box_inner">
                                            <div className="giftcards_ibox">
                                                <img src={item?.image ? item?.image : giftCard} alt="gift card" />
                                            </div>
                                            <div className="giftcards_cbox">
                                                <h4>{item?.name}</h4>
                                                <p>
                                                    Quantity: <span>{item?.quantity}</span>
                                                </p>
                                                <p>
                                                    Price: <span>1</span>
                                                </p>
                                                <ul>
                                                    <li>Pts: {ele?.total_points_redeemed}</li>
                                                    <li className="refunded">Order Placed</li>
                                                </ul>
                                                <small>Gift cards or vouchers once bought cannot be exchanged, refunded or cancelled.</small>
                                            </div>
                                        </div>
                                        <div className="giftcards_obox">
                                            <p>
                                                Order ID: <strong>{ele?.code}</strong> Order On: <strong>{getDate(ele?.order_date, "YYYY-MM-DD hh:mm:ss")}</strong>
                                            </p>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    ) : (
                        <h6 className="text-center"> No Record Found</h6>
                    )}
                </Tab> */}
            </Tabs>
            {showModal && <RedeemModal show={showModal} close={() => setHomeState((prev) => ({ ...prev, showModal: false }))} walletSummary={walletSummary} />}
        </>
    );
};

export default Home;